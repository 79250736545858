export default class User {
  carnet: string
  created_at: string
  dni: string
  email: string
  email_verified_at: string
  home_address: {
    lat: number;
    lng: number;
    street_address: string;
    locality: string;
  }
  id: string
  name: string
  surname: string
  phone: string
  selfie_url: string
  updated_at: string
  work_address: {
    lat: number;
    lng: number;
    street_address: string;
    locality: string;
  }
  user_valorations: {
    id: number;
    user_id: string;
    score: number;
    created_at: string;
    updated_at: string;
    comment: string;
    reviewed_by: string;
  }[]
  user_reviews: {
    id: number;
    user_id: string;
    score: number;
    created_at: string;
    updated_at: string;
    comment: string;
    reviewed_by: string;
  }[]
  score_average: number
  description: string;
  birth_date: string;
  previous_travel_way: string;
}
