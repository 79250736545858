import { Injectable } from '@angular/core';
import { doneTripStatus } from '@app/constants';
import { calculateDistance } from '@app/utils';
import { FamiliasService } from '@services/familias/familias.service';
import { VehiclesService, acceptedCities } from '@services/vehicles/vehicles.service';
import { ViajesService } from '@services/viajes/viajes.service';
import { chinchetasConInfo } from '@app/constants'
import { RouteService } from '@services/route/route.service';
import { AxiosService } from '@services/axios/axios.service';
import { GeolocationService } from '@services/geolocation/geolocation.service';
import { GoogleMapService } from '@services/google-map/google-map.service';
import Activo from '@app/models/Activo';
import { environment } from '@envs';

declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class ChinchetasService {
  chinchetas = []
  currentTrip
  chinchetaTaxi
  chinchetaSelected

  markersNew: string[]
  userLocation

  chinchetasArray = new Map()

  selectedMarker = null

  origin

  selectedActivo

  getUserLocationChinchetasInterval: ReturnType<typeof setInterval>
  getOriginLocationChinchetasInterval: ReturnType<typeof setInterval>

  pusher
  pusherChannel

  constructor(
    private axios: AxiosService,
    private vehicles: VehiclesService,
    private viajes: ViajesService,
    private familias: FamiliasService,
    private routes: RouteService,
    private geolocation: GeolocationService,
    private googleMap: GoogleMapService
  ) {
    this.pusher = new Pusher(environment.pusherKey, {
      cluster: "eu",
    });

    this.familias.familiasActivas.subscribe(() => {
      if (this.chinchetas) {
        this.printChinchetas(this.chinchetas)
      }
    })

    this.viajes.currentTrip.subscribe((currentTrip) => {
      this.currentTrip = currentTrip
      this.printChinchetas([])
    })

    this.routes.origen.subscribe(origen => {
      if (origen) {
        this.origin = origen
        if (acceptedCities.includes(origen.locality)) {
          this.requestChinchetas({
            "lat": origen.coords.lat,
            "lng": origen.coords.lng,
            "locality": origen.locality
          })
          this.clearUserLocationChinchetasInterval()
          this.clearOriginChinchetasIntervals()
          this.setOriginChinchetasIntervals()
        } else if (this.getOriginLocationChinchetasInterval) {
          this.clearOriginChinchetasIntervals()
        }
      }
    })

    this.routes.selectedActivo.subscribe((activo: Activo) => {
      this.selectedActivo = activo
      if (activo?.data) {
        this.resetChinchetas()
        this.printSelectedActivoChincheta()
      }
    })

    this.geolocation.location.subscribe((userLocation) => {
      if (!this.origin || this.origin.place_id === "located") {
        this.userLocation = userLocation
        if (acceptedCities.includes(userLocation?.locality)) {
          this.clearOriginChinchetasIntervals()
          this.clearUserLocationChinchetasInterval()
          this.setUserLocationChinchetasInterval()
        } else if (this.getUserLocationChinchetasInterval) {
          this.clearUserLocationChinchetasInterval()
        }
      }
    })

    this.vehicles.selectedActivo.subscribe(selected => {
      this.selectChincheta(selected)
    })
  }

  printChinchetas(chinchetas) {
    if (this.googleMap.mapa) {
      if ((!this.currentTrip || doneTripStatus.includes(this.currentTrip.state)) && !this.selectedActivo) {
        const userLocation = new google.maps.LatLng(this.userLocation?.lat, this.userLocation?.lng)
        const originLocation = new google.maps.LatLng(this.origin?.coords.lat, this.origin?.coords.lng)
        
        this.markersNew = []
        chinchetas.map(chincheta => {
          const chinchetaCoords = new google.maps.LatLng(chincheta.lat, chincheta.lng)
          const distanceToUser = calculateDistance(userLocation, chinchetaCoords)
          const distanceToOrigin = calculateDistance(originLocation, chinchetaCoords)
          const radius = chincheta.familia === 'taxi' ? 1000 : 750

          if (distanceToUser <= radius || distanceToOrigin <= radius) {
            this.printAvailableChincheta(chincheta)
          }
        })

        Object.keys(this.chinchetasArray).map(id => {
          if (!this.markersNew.includes(id)) {
            if (this.selectedMarker === id) {
              this.selectedMarker = null
            }
            this.chinchetasArray[id].marker?.setMap(null)
            delete this.chinchetasArray[id]
          }
        })
      } else if (this.currentTrip?.state && !doneTripStatus.includes(this.currentTrip?.state) && this.currentTrip.lat && this.currentTrip.lng) {
        this.printCurrentTripChincheta()
      } else if (this.selectedActivo) {
        this.printSelectedActivoChincheta()
      }
    }
  }

  printAvailableChincheta(chincheta) {
    const markerIcon = {
      url: `assets/iconosmapa/Activo_${chincheta.familia}_${chincheta.operador}.png`,
      scaledSize: new google.maps.Size(40, 60),
    }
    const selectedMarkerIcon = {
      url: `assets/iconosmapa/Activo_${chincheta.familia}_${chincheta.operador}_selected.png`,
      scaledSize: new google.maps.Size(40, 60),
    }
    const id = chincheta.id

    if (this.chinchetasArray[id]?.marker) {
      this.chinchetasArray[id].marker.setPosition(new google.maps.LatLng(chincheta.lat, chincheta.lng))
      if (this.selectedMarker === id) {
        this.chinchetasArray[id].marker.setIcon(selectedMarkerIcon)
      } else {
        this.chinchetasArray[id].marker.setIcon(markerIcon)
      }
    } else {
      const selected = this.vehicles.selectedActivo.getValue()?.id === chincheta?.id
      const markerChincheta = new google.maps.Marker({
        icon: selected ? selectedMarkerIcon : markerIcon,
        map: this.googleMap.mapa,
        position: new google.maps.LatLng(chincheta.lat, chincheta.lng),
        animation: google.maps.Animation.DROP,
      })
      if (chinchetasConInfo.includes(chincheta.operador)) {
        markerChincheta.addListener("click", async () => {
          const chinchetaInfo = await this.getChinchetaInfo(chincheta.id)

          this.vehicles.selectActivo(chinchetaInfo)
        });
      }
      this.chinchetasArray[id] = {
        marker: markerChincheta,
        icon: markerIcon,
        selectedIcon: selectedMarkerIcon
      }
    }

    this.markersNew = [...this.markersNew, id]
  }

  selectChincheta(chincheta) {
    if (this.selectedMarker) {
      const icon = this.chinchetasArray[this.selectedMarker].icon
      this.chinchetasArray[this.selectedMarker].marker.setIcon(icon)
    }

    if (chincheta) {
      const id = chincheta.id

      this.selectedMarker = id
      this.googleMap.centerMap(
        {
          lat: chincheta.lat,
          lng: chincheta.lng
        },
        true
      )
      
      this.chinchetasArray[id].marker.setIcon(this.chinchetasArray[id].selectedIcon)
    }
  }

  printCurrentTripChincheta() {
    this.resetChinchetas()
    if (this.currentTrip.lat && this.currentTrip.lng) {
      if (this.chinchetaTaxi) {
        this.chinchetaTaxi.setPosition(new google.maps.LatLng(this.currentTrip.lat, this.currentTrip.lng))
      } else {
        const markerIcon = {
          url: `assets/iconosmapa/Activo_taxi_taxi033.png`,
          scaledSize: new google.maps.Size(40, 60),
        }
        const markerChincheta = new google.maps.Marker({
          icon: markerIcon,
          map: this.googleMap.mapa,
          position: new google.maps.LatLng(this.currentTrip.lat, this.currentTrip.lng)
        })
        this.chinchetaTaxi = markerChincheta
      }
    }
  }

  printSelectedActivoChincheta() {
    this.resetChinchetas()

    const activo = this.selectedActivo.data

    const coords = {
      lat: activo.lat ?? activo.extras.latitud,
      lng: activo.lng ?? activo.extras.longitud
    }

    if (coords.lat, coords.lng) {
      if (this.chinchetaSelected) {
        this.chinchetaSelected.setPosition(new google.maps.LatLng(coords.lat, coords.lng))
      } else {
        const markerIcon = {
          url: `assets/iconosmapa/Activo_${activo.familia}_${activo.operador}_selected.png`,
          scaledSize: new google.maps.Size(40, 60),
        }
        const markerChincheta = new google.maps.Marker({
          icon: markerIcon,
          map: this.googleMap.mapa,
          position: new google.maps.LatLng(coords.lat, coords.lng)
        })
        this.chinchetaSelected = markerChincheta
      }
    }
  }

  resetChinchetas() {
    Object.keys(this.chinchetasArray).map(id => {
      this.chinchetasArray[id]?.marker?.setMap(null)
    })

    this.chinchetasArray = new Map()
    if (this.chinchetaTaxi && (!this.currentTrip || (this.currentTrip?.state && doneTripStatus.includes(this.currentTrip?.state)))) {
      this.chinchetaTaxi.setMap(null)
    }
  }

  async requestChinchetas(location: {lat: number, lng: number, locality: string}) {
    const bodyRequest = {
      location
    };

    if (this.pusherChannel) {
      this.pusher.unsubscribe(this.pusherChannel);
      this.pusherChannel = null
    }

    if (this.chinchetas) {
      this.printChinchetas(this.chinchetas);
      this.chinchetas = []
    }

    (await this.axios.getInstance())
      .post('/request-chinchetas', bodyRequest)
      .then((response) => {
        this.pusherChannel = `cache-request_chinchetas.${response.data.identifier}`

        const channel = this.pusher.subscribe(this.pusherChannel)

        channel.bind_global(async (_, data) => {
          if (data.chinchetas) {
            this.chinchetas = [...this.chinchetas, ...Object.values(data.chinchetas)]
          }
        });
      })
  }

  async getChinchetaInfo(id) {
    return (await this.axios.getInstance())
      .get(`/chinchetas/${id}`)
      .then((response) => {
        return response.data
      })
  }

  setUserLocationChinchetasInterval() {
    this.getUserLocationChinchetasInterval = setInterval(() => {
      this.requestChinchetas({
        "lat": this.userLocation.coords.lat,
        "lng": this.userLocation.coords.lng,
        "locality": this.userLocation.locality
      })
    }, 5000)
  }

  clearUserLocationChinchetasInterval() {
    clearInterval(this.getUserLocationChinchetasInterval)
  }

  setOriginChinchetasIntervals() {
    this.getOriginLocationChinchetasInterval = setInterval(() => {
      this.requestChinchetas({
        "lat": this.origin.coords.lat,
        "lng": this.origin.coords.lng,
        "locality": this.origin.locality
      })
    }, 5000)
  }

  clearOriginChinchetasIntervals() {
    clearInterval(this.getOriginLocationChinchetasInterval)
  }
}
