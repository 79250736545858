import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '@envs';
import { PaymentMethodsService } from '@services/payment-methods/payment-methods.service';

declare const Stripe

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss'],
})
export class AddPaymentMethodComponent implements OnInit {
  @Output() updateStatus: EventEmitter<any> = new EventEmitter()
  stripe = Stripe(environment.stripeKey)
  elements = this.stripe.elements()
  processing: boolean = false
  cardButton;

  constructor(private paymentMethodService: PaymentMethodsService) { }

  ngOnInit() {
    const style = {
      base: {
        iconColor: '#1dffcf',
        color: '#fff',
        fontWeight: '400',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#ffffff80',
          fontWeight: '400',
        },
      },
      invalid: {
        iconColor: '#ef4444',
        color: '#ef4444',
      },
    }
    const classes = {
      complete: 'border-primary border-2',
      focus: 'border-primary border-2',
      invalid: 'bg-white border-2 border-red-500'
    }

    const cardNumber = this.elements.create('cardNumber', { style, classes });
    const cardExpiry = this.elements.create('cardExpiry', { style, classes });
    const cardCvc = this.elements.create('cardCvc', { style, classes });


    cardNumber.mount('#card-number');
    cardExpiry.mount('#card-expiry');
    cardCvc.mount('#card-cvc');

    this.cardButton = document.getElementById('card-button')

    this.cardButton.addEventListener('click', async (e) => {
      this.processing = true
      const { token, error } = await this.stripe.createToken(cardNumber)

      await this.paymentMethodService.addCard(token.id)

      this.processing = false

      this.goBack()

      if (error) {
        console.error(error.message)
      } else {
        console.info("The card has been verified successfully...")
      }
    })
  }

  ngOnDestroy() {
    this.cardButton.removeAllListeners()
  }

  goBack() {
    this.updateStatus.emit('list')
  }
}
