import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { cardsIcons } from '@app/constants';
import { ModalsService } from '@services/modals/modals.service';
import { PaymentMethodsService } from '@services/payment-methods/payment-methods.service';

@Component({
  selector: 'app-list-payment-methods',
  templateUrl: './list-payment-methods.component.html',
})
export class ListPaymentMethodsComponent implements OnInit {
  @Output() updateStatus: EventEmitter<any> = new EventEmitter()

  cards: any[]
  selectedCard: number = -1

  cardsIcons = cardsIcons

  constructor(
    private paymentMethodsService: PaymentMethodsService,
    private modalsService: ModalsService,
  ) {
    this.paymentMethodsService.selectedCard.subscribe(selectedCard => {
      this.selectedCard = selectedCard
    })
  }

  async ngOnInit() {
    this.paymentMethodsService.cards.subscribe(cards => {
      this.cards = cards
    })
  }

  selectCard(index) {
    this.paymentMethodsService.selectCard(index)
    this.modalsService.destroyModal('modalPayment')
  }
}
