import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import Activo, { Type } from '@app/models/Activo';
import { Orders } from '@app/models/Orders';
import { displayDate } from '@app/utils';
import { ComparadorService } from '@services/modals/comparador/comparador.service';
import { VehiclesService } from '@services/vehicles/vehicles.service';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-comparador',
  templateUrl: './comparador.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparadorComponent implements OnInit {
  types: {[key in Type]: boolean} = {
    rider: true,
    driver: true,
    public: false
  }
  assetsSubscription: Subscription
  requestedSubscription: Subscription
  assets: {[key in Type]: Activo[]} = {
    rider: [],
    driver: [],
    public: []
  }
  requested: boolean;
  sortBy: Orders = 'price'

  activeType: 'rider' | 'driver' | 'public' = 'rider'

  filteredAssets = []

  departureTime: string;

  displayDepartureTime: string = "Ahora";

  selectedActivo: string;

  constructor(
    private comparador: ComparadorService,
    private vehicles: VehiclesService,
    private ref: ChangeDetectorRef,
  ) { }

  originalOrder = (_: KeyValue<Type, Activo[] | boolean>, __: KeyValue<Type, Activo[] | boolean>): number => {
    return 0;
  }

  changeActivosOrder = (order) => {
    this.sortBy = order
  }

  ngOnInit() {
    this.assetsSubscription = this.vehicles.activos.subscribe(activos => {
      this.assets = activos
      this.filteredAssets = this.filterAssets(activos)
      this.ref.detectChanges()
    })

    this.requestedSubscription = this.vehicles.requested.subscribe(requested => {
      this.requested = requested
      this.ref.detectChanges()
    })

    this.comparador.activeType.subscribe(activeType => {
      this.activeType = activeType
      this.filteredAssets = this.filterAssets(this.assets)
      this.ref.detectChanges()
    })
  }

  onSelectActivo(activo) {
    this.selectedActivo = activo
  }

  filterAssets(activos) {
    let carpoolingSeen = false
    const filteredAssets = activos[this.activeType].filter(asset => {
      const carpooling = asset.type === 'rider' && asset.operador.name === 'car-pooling' && asset.tarifaable_id
      if (carpooling && carpoolingSeen) {
        return false
      }
      if (asset.type === 'rider' && asset.operador.name === 'car-pooling' && asset.tarifaable_id) {
        carpoolingSeen = true
      }
      return true
    })

    const carpoolingWithTarifaable = activos.rider.filter(activo => activo.operador.name === 'car-pooling' && activo.tarifaable_id)

    if (this.activeType === 'rider' && carpoolingWithTarifaable.length === 0) {
      const createCarpooling = activos.driver.find(activo => activo.operador.name === 'car-pooling' && !activo.tarifaable_id)

      if (createCarpooling) {
        filteredAssets.unshift({...createCarpooling, tarifaable_id: "test", type: "rider"})
      }
    }

    if (this.displayDepartureTime !== "Ahora") {
      return filteredAssets.filter(asset => asset.operador.name === 'car-pooling' || asset.type !== 'rider')
    }
    return filteredAssets
  }

  ngOnDestroy() {
    this.assetsSubscription.unsubscribe()
    this.requestedSubscription.unsubscribe()
  }

  onDepartureTimeChange(value) {
    this.departureTime = value
    if (dayjs(this.departureTime).isSame(dayjs(), "minute")) {
      this.displayDepartureTime = "Ahora"
    } else {
      this.displayDepartureTime = displayDate(value, { onlyDayShort: true }) + " " + displayDate(value, { onlyHour: true })
    }

    this.filteredAssets = this.filterAssets(this.assets)
  }
}
