import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComparadorService } from '@services/modals/comparador/comparador.service';

@Component({
  selector: 'app-pooling-wip',
  templateUrl: './pooling-wip.component.html',
  styleUrls: ['./pooling-wip.component.scss'],
})
export class PoolingWipComponent implements OnInit {
  isOpen: boolean = false;

  constructor(
    private comparador: ComparadorService
  ) {
    this.comparador.isPoolingWipModalOpen.subscribe((isOpen) => {
      this.isOpen = isOpen;
    });
  }

  ngOnInit() {}

  close() {
    this.comparador.closePoolingWipModal();
  }
}
