// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrl: 'https://backend.dev.waiis.eco/api',
  // apiUrl: 'http://localhost:8000/api',
  production: false,
  mapsKey: 'AIzaSyDA62dvs0YXkbbI_rUKIhoIFbqNOjdTWSE',
  stripeKey: 'pk_test_51LLPWrApveoBbcbcq6dQnWCPH3n8mqEYtFf88g3lpk2ZfO16u0MhfId4AI9n1y8rR5R5S661SOigB3MzjUYBYbFF00iGLKImh4',
  pusherKey: '4c991ee6b19e224c80b6',
  firebaseConfig: {
    apiKey: 'AIzaSyDA62dvs0YXkbbI_rUKIhoIFbqNOjdTWSE',
    authDomain: 'promising-silo-345210.firebaseapp.com',
    projectId: 'promising-silo-345210',
    storageBucket: 'promising-silo-345210.appspot.com',
    messagingSenderId: '958867453208',
    appId: '1:958867453208:web:64cf2d49c6c34d89279a6d',
    measurementId: 'G-CVLW9BYE87',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
