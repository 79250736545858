import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import Activo, { Type } from '@app/models/Activo';
import { Orders } from '@app/models/Orders';
import { VehiclesService } from '@services/vehicles/vehicles.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-comparador',
  templateUrl: './carpooling-results.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarpoolingResultsComponent implements OnInit {
  types: {[key in Type]: boolean} = {
    rider: true,
    driver: true,
    public: false
  }
  assetsSubscription: Subscription
  requestedSubscription: Subscription
  requested: boolean;
  sortBy: Orders = 'price'

  activeType: 'rider' | 'driver' | 'public' = 'rider'

  filteredAssets = []

  constructor(
    private vehicles: VehiclesService,
    private ref: ChangeDetectorRef,
  ) { }

  originalOrder = (_: KeyValue<Type, Activo[] | boolean>, __: KeyValue<Type, Activo[] | boolean>): number => {
    return 0;
  }

  changeActivosOrder = (order) => {
    this.sortBy = order
  }

  ngOnInit() {
    this.assetsSubscription = this.vehicles.activos.subscribe(activos => {
      this.filteredAssets = activos.rider.filter(asset => asset.operador.name === 'car-pooling' && asset.tarifaable_id)
      this.ref.detectChanges()
    })

    this.requestedSubscription = this.vehicles.requested.subscribe(requested => {
      this.requested = requested
      this.ref.detectChanges()
    })
  }

  ngOnDestroy() {
    this.assetsSubscription.unsubscribe()
    this.requestedSubscription.unsubscribe()
  }
}
