import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import dayjs from 'dayjs';

@Component({
  selector: 'app-departure-time-modal',
  templateUrl: './departure-time-modal.component.html',
  styleUrls: ['./departure-time-modal.component.scss'],
})
export class DepartureTimeModalComponent implements OnInit {
  today: string = dayjs().format();
  departureTime: string = dayjs().format();

  @Output() onChange = new EventEmitter<string>()

  constructor() { }

  ngOnInit() {}

  onDepartureTimeChange(event) {
    this.onChange.emit(event.detail.value)
  }
}
