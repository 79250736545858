import { Component, OnInit } from '@angular/core'
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane'

import { AuthenticationService } from '@services/authentication/authentication.service'
import { Router } from '@angular/router'
import User from '@app/models/User'
import { UserService } from '@services/user/user.service'
import { ToastService } from '@services/toast/toast-service'
import { MyWaiisService } from '@services/modals/my-waiis/my-waiis.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent implements OnInit {
  menuPages = [
    { title: 'sidemenu.login', url: '/login', only: 'non-users' },
    { title: 'sidemenu.my_profile', url: '/account', only: 'users' },
    { title: 'sidemenu.my_wallet', url: '/wallet', only: 'users' },
    { title: 'sidemenu.trip_history', url: '/trip-history', only: 'users' },
    { title: 'sidemenu.language', url: '/language', only: 'users' },
    { title: 'sidemenu.promotion_codes', url: '/promotion-codes', only: 'users' },
    { title: 'sidemenu.help_contact', url: '/contacto' },
    { title: 'sidemenu.privacy_policy', url: 'https://waiis.eco/politica-privacidad/', external: true },
    { title: 'sidemenu.legal_conditions', url: `https://waiis.eco/aviso-legal/`, external: true }
  ]
  user: User = new User
  isLoggedIn = false
  swipeEnabled = false
  modalLogout: CupertinoPane
  modalLogoutSettings: CupertinoSettings = {
    bottomClose: false,
    fitHeight: true,
    fastSwipeClose: false,
    handleKeyboard: false,
    parentElement: 'body',
    buttonDestroy: false,
    showDraggable: false,
    draggableOver: false,
    dragBy: []
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private auth: AuthenticationService,
    private toast: ToastService,
    private translate: TranslateService,
    private myWaiis: MyWaiisService
  ) { }

  ngOnInit() {
    this.modalLogout = new CupertinoPane(
      '.modalLogout',
      this.modalLogoutSettings
    )

    this.userService.user.subscribe(user => {
      if (user) {
        this.user = user
      }
    })

    this.auth.isAuthenticated.subscribe((isAuthenticated) => {
      this.isLoggedIn = isAuthenticated
    })
  }

  menuOpened() {
    this.swipeEnabled = true
  }

  menuWillOpen() {
    this.myWaiis.setSideMenuVisible(true)
  }

  menuWillClose() {
    this.myWaiis.setSideMenuVisible(false)
  }

  menuDidClose() {
    this.swipeEnabled = false
  }

  navigate(index) {
    const page = this.menuPages[index]
    if (page.external) {
      window.open(page.url)
    } else {
      this.router.navigate([page.url])
    }
  }

  logout() {
    this.modalLogout.destroy().then(() => {
      this.auth.logout()
      this.toast.presentToast(this.translate.instant('sidemenu.logout_success'), 'info');
    })
  }

  async askLogout() {
    try {
      await this.modalLogout.present()
    } catch (_) {
      this.modalLogout.present()
    }
  }
}
