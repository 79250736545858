import { Injectable } from '@angular/core';
import { ComparadorComponent } from '@components/comparador/comparador.component';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { MyWaiisService } from '../my-waiis/my-waiis.service';

@Injectable({
  providedIn: 'root'
})
export class ComparadorService {
  modal
  breakpoints = [0, 0.45, 0.7, 1]
  screenHeight: number
  open = new BehaviorSubject<boolean>(false)
  currentBreakpoint = new BehaviorSubject<number>(0.7)

  isPoolingWipModalOpen = new BehaviorSubject<boolean>(false)

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private myWaiis: MyWaiisService
  ) {
    platform.ready().then(() => {
      this.screenHeight = platform.height()
    });
  }

  activeType = new BehaviorSubject<'rider' | 'driver' | 'public'>('rider')

  changeType = (type: 'rider' | 'driver' | 'public') => {
    this.activeType.next(type)
  }

  openPoolingWipModal = () => {
    this.isPoolingWipModalOpen.next(true)
  }

  closePoolingWipModal = () => {
    this.isPoolingWipModalOpen.next(false)
  }

  async openModal() {
    this.myWaiis.setComparadorVisible(true)
    this.modal = await this.modalCtrl.create({
      component: ComparadorComponent,
      breakpoints: this.breakpoints,
      initialBreakpoint: 0.7,
      backdropDismiss: false,
      showBackdrop: false,
      id: "modalComparador",
      cssClass: this.platform.is("android") && "android"
    });
    this.modal.present();
    this.open.next(true)

    this.modal.addEventListener('ionBreakpointDidChange', (event) => {
      this.currentBreakpoint.next(event.detail.breakpoint)
    })

    await this.modal.onWillDismiss();
    this.onDismiss()
  }

  onDismiss() {
    this.open.next(false)
    this.myWaiis.setComparadorVisible(false)
  }

  dismiss() {
    console.warn("debug dismiss")
    this.modal?.dismiss()
  }
}
