import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
})
export class InputSelectComponent implements OnInit {
  @Input() placeholder: string;
  @Input() options: {value: any, text: string, extra?: string}[];
  @Input() selectedText: string;
  @Output() onChange = new EventEmitter<any>();
  @Input() customElement = undefined;
  @Input() class: string = '';

  @ViewChild('elementRef') elementRef: ElementRef;

  open = false;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() { }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.open = false;
    }
  }

  changeValue(value) {
    this.onChange.emit(value);
    this.open = false;
  }
  
  toggleState() {
    this.open = !this.open;
  }

  sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
