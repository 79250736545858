import memoizee from 'memoizee';
import 'dayjs/locale/es';
import { AppLauncher } from '@capacitor/app-launcher';

import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import * as isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import dayjs_es from '@src/dayjs_es';
import { operatorsAppStores, operatorsUrlSchemes } from './constants';
import { TranslateHelper } from '@services/translate/translate.helper';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);

dayjs.updateLocale('es', dayjs_es);

function calculateDistance(position1: google.maps.LatLng, position2: google.maps.LatLng) {
  var R = 6378 * 1000 // Radius of the Earth
  var rlat1 = position1.lat() * (Math.PI / 180) // Convert degrees to radians
  var rlat2 = position2.lat() * (Math.PI / 180) // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (position2.lng() - position1.lng()) * (Math.PI / 180) // Radian difference (longitudes)

  var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)))
  return d
}

let throttlePause;
const throttle = (callback, time) => {
  if (throttlePause) return;

  throttlePause = true;
  
  callback();
  
  setTimeout(() => {
    throttlePause = false;
  }, time);
};

const formatPrice = (price: number | string, estimate?: number): string => {
  let formattedPrice = parseFloat(String(price || 0).replace(/,/g, ''));

  if (estimate) {
    const min = Math.trunc(formattedPrice / 100)
    const max = Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(min + estimate);
    return `${min}-${max}`
  }

  return Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  }).format(formattedPrice / 100);
}

const formatTime = memoizee((time: number): string => {
  return dayjs.duration(time, 'seconds').locale('es').humanize();
})

const formatCo2 = memoizee((co2: number): string => {
  return `${Math.round(co2)} g`;
})

const millisecondsToMinutesAndSeconds = memoizee((millis) => {
  const date = new Date(millis)

  const minutes = date.getMinutes().toString()
  const seconds = date.getSeconds().toString().padStart(2, '0')

  return minutes + ":" + seconds;
})

function displayDate(
  date,
  options?: {
    onlyDay?: boolean;
    onlyHour?: boolean;
    onlyDayShort?: boolean;
  }
) {
  const aWeekFromNow = dayjs().add(6, 'day')

  const lang = TranslateHelper.getLang()

  if (options?.onlyDayShort) {
    if (dayjs(date).isSameOrBefore(dayjs())) {
      return TranslateHelper.instant('common.today')
    } else if (dayjs(date).isSameOrBefore(dayjs().add(1, 'day'))) {
      return TranslateHelper.instant('common.tomorrow')
    } else if (dayjs(date).isAfter(dayjs(aWeekFromNow))) {
      return dayjs(date).locale(lang).format('D MMMM')
    } else {
      return capitalizeFirstLetter(dayjs(date).locale(lang).format('dddd'))
    }
  }
  else if (options?.onlyDay) {
    return dayjs(date).locale(lang).format('dddd D MMMM YYYY')
  } else if (options?.onlyHour) {
    return dayjs(date).locale(lang).format('HH:mm')
  } else {
    return dayjs(date).locale(lang).format('dddd, D MMMM, HH:mm')
  }
}

async function launchApp(operator: string, platforms) {
  const platform = platforms.includes('ios') ? 'iphone' : platforms.includes('android') ? 'android' : null

  if (platform) {
    const { completed } = await AppLauncher.openUrl({ url: operatorsUrlSchemes[operator][platform] })
    if (!completed) {
      AppLauncher.openUrl({ url: operatorsAppStores[operator][platform] })
    }
  }
}

function sortTripsByCreationDate(tripA, tripB) {
  const tripADate = new Date(tripA.created_at)
  const tripBDate = new Date(tripB.created_at)
  if (tripADate > tripBDate) {
    return -1
  } else if (tripADate < tripBDate) {
    return 1
  }
  return 0
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
  calculateDistance,
  throttle,
  formatPrice,
  formatTime,
  formatCo2,
  millisecondsToMinutesAndSeconds,
  displayDate,
  launchApp,
  sortTripsByCreationDate,
  capitalizeFirstLetter
}