import { Component, OnInit } from '@angular/core';
import { GeolocationService } from '@services/geolocation/geolocation.service';
import { ModalInformService } from '@services/modals/modal-inform/modal-inform.service';
import { ToastService } from '@services/toast/toast-service';
import { UserService } from '@services/user/user.service';
import { ViajesService } from '@services/viajes/viajes.service';

@Component({
  selector: 'app-modal-inform',
  templateUrl: './modal-inform.component.html',
})
export class ModalInformComponent implements OnInit {
  inputText: string
  date: Date
  userLocation
  sending: boolean = false

  constructor(
    private viajesService: ViajesService,
    private geolocationService: GeolocationService,
    private userService: UserService,
    private toastService: ToastService,
    private modalInformService: ModalInformService
  ) { }

  ngOnInit() {
    this.date = new Date()
    this.userLocation = this.geolocationService.location.getValue()
  }

  clearInput() {
    this.inputText = ''
  }

  sendReport() {
    this.sending = true
    const trip = this.viajesService.tripToReport

    const info = {
      user_email: this.userService.user.getValue().email,
      user_name: this.userService.user.getValue().name,
      user_lastName: this.userService.user.getValue().surname,
      user_phone: this.userService.user.getValue().phone,
      id_waiis: trip.id,
      id_operador: trip.service_id,
      nombre_conductor: trip.extras.nomcond,
      telefono_conductor: trip.extras.telcond,
      hora: this.date.toISOString(),
      hora_solicitud: trip.created_at,
      estado_viaje: trip.state,
      geolocalizacion_usuario: this.userLocation.coords,
      geolocalizacion_taxi: {
        lat: trip.lat,
        lng: trip.lng
      },
      descripcion: this.inputText
    }

    this.viajesService.reportProblem(info)
      .then(() => {
        this.sending = false
        this.toastService.presentToast('Tu mensaje se ha enviado correctamente', 'info', { position: 'top' })
        this.modalInformService.dismiss()
      })
      .catch(() => {
        this.sending = false
        this.toastService.presentToast(
          'Ha habido un error al enviar tu mensaje. Prueba de nuevo más tarde, o ponte en contacto con nosotros.',
          'error',
          { position: 'top' }
        )
      })

    this.clearInput()
  }

  close() {
    this.clearInput()
    this.modalInformService.dismiss()
  }
}
