import { Component, OnInit } from '@angular/core';
import { DeleteRoutineService } from '@services/modals/delete-routine/delete-routine.service';

@Component({
  selector: 'app-delete-routine-modal',
  templateUrl: './delete-routine-modal.component.html',
  styleUrls: ['./delete-routine-modal.component.scss'],
})
export class DeleteRoutineModalComponent implements OnInit {

  constructor(
    private deleteRoutine: DeleteRoutineService
  ) { }

  ngOnInit() {}

  dismiss() {
    this.deleteRoutine.dismiss()
  }

  delete() {
    this.deleteRoutine.deleteRoutine()
  }
}
