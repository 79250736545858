// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrl: 'https://backend.dev.waiis.eco/api',
  // apiUrl: 'http://localhost:8000/api',
  production: false,
  mapsKey: 'AIzaSyDA62dvs0YXkbbI_rUKIhoIFbqNOjdTWSE',
  stripeKey: 'pk_live_51LLPWrApveoBbcbcCE7ckCiEjy9oE5SOIqEg8J3WOP6XGSpTp17NfQSYKC1XYcsf6TPsNe8DumFxPGgSQWtVBN2z000xP44Bxs',
  pusherKey: 'f311edda739a924f3705',
  firebaseConfig: {
    apiKey: "AIzaSyDA62dvs0YXkbbI_rUKIhoIFbqNOjdTWSE",
    authDomain: "waiis-api-prod.firebaseapp.com",
    projectId: "waiis-api-prod",
    storageBucket: "waiis-api-prod.appspot.com",
    messagingSenderId: "679293452944",
    appId: "1:679293452944:web:2cc6008e26dd9e5ac60e15",
    measurementId: "G-QC2L0BR7VL"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
