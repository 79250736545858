import 'dayjs/locale/es';

import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as updateLocale from 'dayjs/plugin/updateLocale';

import { Injectable } from '@angular/core';
import dayjs_es from '@src/dayjs_es';
import memoizee from 'memoizee';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('es', dayjs_es);


@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() { }

  formatPrice = memoizee((price: number | string, estimate: boolean = false): string => {
    let formattedPrice = parseFloat(String(price).replace(/,/g, ''));
    
    if (estimate) {
      const min = Math.trunc(formattedPrice / 100)
      const max = Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(min + 3);
      return `${min} - ${max}`
    }

    return Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
    }).format(formattedPrice / 100);
  })

  formatTime = memoizee((time: number): string => {
    return dayjs.duration(time, 'seconds').locale('es').humanize();
  })

  formatCo2 = memoizee((co2: number): string => {
    return `${Math.round(co2)} g`;
  })

  formatDistance = memoizee((distance: number): string => {
    if (distance > 1000) {
      const roundedDistance = Math.round((distance / 1000 + Number.EPSILON) * 100) / 100
      return `${Intl.NumberFormat('es-ES', {
        style: 'decimal',
      }).format(roundedDistance)} km`
    } else {
      return `${distance} m`
    }
  })

  capitalizeFirst = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
