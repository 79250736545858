import { Component, OnInit } from '@angular/core';
import { GeolocationService } from '@services/geolocation/geolocation.service';
import { LocationPermissionsService } from '@services/modals/locationPermissions/location-permissions.service';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { Geolocation } from '@capacitor/geolocation';

@Component({
  selector: 'app-location-permissions-modal',
  templateUrl: './location-permissions-modal.component.html',
  styleUrls: ['./location-permissions-modal.component.scss'],
})
export class LocationPermissionsModalComponent implements OnInit {

  constructor(
    private locationPermissionsService: LocationPermissionsService,
    private geolocation: GeolocationService
  ) { }

  ngOnInit() {}

  requestPermissions() {
    Geolocation.checkPermissions().then(result => {
      if (result.location === "granted") {
        this.geolocation.permission.next(true)
      } else if (result.location === "denied") {
        NativeSettings.open({
          optionAndroid: AndroidSettings.ApplicationDetails, 
          optionIOS: IOSSettings.App
        })
      } else {
        Geolocation.requestPermissions().then(result => {
          if (result.location === "granted") {
            this.geolocation.permission.next(true)
          }
        })
      }
    })

    this.dismissModal()
  }

  dismissModal() {
    this.locationPermissionsService.dismiss()
  }
}
