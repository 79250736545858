import { Injectable } from '@angular/core';
import { Type } from '@app/models/Activo';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { MyWaiisService } from '../my-waiis/my-waiis.service';
import { ModalsService } from '../modals.service';
import { ModalInformComponent } from '@components/modal-inform/modal-inform.component';

@Injectable({
  providedIn: 'root'
})
export class ModalInformService {
  modal
  open = new BehaviorSubject<boolean>(false)
  constructor(
    private modalCtrl: ModalController,
    private myWaiis: MyWaiisService,
    private modalsService: ModalsService,
    private platform: Platform
  ) { }

  types = new BehaviorSubject<{[key in Type]: boolean}>({
    rider: true,
    driver: true,
    public: true
  })

  toggleType = (type: Type) => {
    this.types.next({
      ...this.types.getValue(),
      [type]: !this.types.getValue()[type]
    })
  }

  async openModal() {
    this.myWaiis.setModalInformVisible(true)
    this.modalsService.destroyModal('modalTripStatus')

    this.modal = await this.modalCtrl.create({
      component: ModalInformComponent,
      id: "modalInform",
      cssClass: this.platform.is("android") && "android"
    });

    this.modal.present();
    this.open.next(true)

    await this.modal.onWillDismiss();
    this.open.next(false)
    this.myWaiis.setModalInformVisible(false)
  }

  dismiss() {
    this.modal.dismiss()
  }
}
