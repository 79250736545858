import { Injectable } from '@angular/core';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { AxiosService } from '@services/axios/axios.service';
import { ToastService } from '@services/toast/toast-service';
import { BehaviorSubject } from 'rxjs';
import { Stripe } from '@capacitor-community/stripe';
import { Platform } from '@ionic/angular';
import Card from '@app/models/Card';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {
  public cards = new BehaviorSubject<Card[]>(null);
  public selectedCard = new BehaviorSubject<number>(-1)

  constructor(
    private axios: AxiosService,
    private toastService: ToastService,
    private authService: AuthenticationService,
    private platform: Platform
  ) {
    this.authService.isAuthenticated.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.getCards()
        this.cards.subscribe(cards => {
          if (cards?.length > 0) {
            const lastUsedCard = this.getlastUsedCard()
            this.selectCard(lastUsedCard ?? 0)
          }
        })
      }
    })
  }

  boolifyPromise(promise) {
    return promise.then(() => true).catch(() => false);
  }


  async getCards() {
    (await this.axios.getInstance())
      .get('/card')
      .then(async response => {
        this.addCardsWithGoogleAndApple(response.data)
      })
  }

  addCard(cardToken) {
    const bodyRequest = {
      card_token: cardToken
    };
    return new Promise(async (resolve, reject) => {
      (await this.axios.getInstance())
        .post('/card', bodyRequest)
        .then(async response => {
          resolve('success')
          this.addCardsWithGoogleAndApple(response.data)
        })
        .catch(error => {
          reject(error.response.data.message)
          this.toastService.presentToast(error.response.data.message, 'error')
        })
    });
  }

  selectCard(index) {
    this.selectedCard.next(index)
    localStorage.setItem('lastUsedCard', index);
  }

  getlastUsedCard() {
    return localStorage.getItem('lastUsedCard');
  }

  async deleteCard(cardId) {
    (await this.axios.getInstance())
      .delete(`/card/${cardId}`)
      .then(async response => {
        this.addCardsWithGoogleAndApple(response.data)
      })
  }

  async addCardsWithGoogleAndApple(cards) {
    let newCards = cards

    if (this.platform.is('capacitor')) {
      const isApplePayAvailable = await this.boolifyPromise(Stripe.isApplePayAvailable());

      if (isApplePayAvailable) {
        newCards = [Object({brand: 'Apple'}), ...newCards]
      }

      const isGooglePayAvailable = await this.boolifyPromise(Stripe.isGooglePayAvailable());

      if (isGooglePayAvailable) {
        newCards = [Object({brand: 'Google'}), ...newCards]
      }
    }

    this.cards.next(newCards)
  }

  reset() {
    this.cards.next(null)
    this.selectedCard.next(-1)
  }
}
