import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { formatPrice } from '@app/utils';
import { RoutinesService } from '@services/routines/routines.service';
import { TranslateService } from '@services/translate/translate.service';
import { UserService } from '@services/user/user.service';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

@Component({
  selector: 'app-route-details',
  templateUrl: './route-details.component.html',
  styleUrls: ['./route-details.component.scss'],
})
export class RouteDetailsComponent implements OnInit {
  public loaded = false;

  @Output() backToCalendar = new EventEmitter<void>();
  @Input() route;
  routeStatus: 'Pendiente' | 'Completado' | 'No completado';
  departureTime: string;
  routeDay: string;
  origin: string;
  destination: string;
  price: string;
  driver: {
    name: string;
    selfie_url: string;
  };
  passengers: {
    name: string;
    selfie_url: string;
  }[];

  constructor(
    private translate: TranslateService,
    private routines: RoutinesService,
    private router: Router,
    private user: UserService
  ) {}

  async ngOnInit() {
    const route = await this.routines.getRouteById(this.route.data.id);

    this.price = formatPrice(route.price);

    this.origin = route.origin.long_address;
    this.destination = route.destination.long_address;

    this.departureTime = dayjs(this.route?.startTime)
      .locale(this.translate.getCurrentLang())
      .format('HH:mm[h]');
    this.routeDay = dayjs(this.route?.startTime)
      .locale(this.translate.getCurrentLang())
      .format('LL');

    this.setStatus();
    this.setDriver(route);
    this.setPassengers(route);
    this.loaded = true;
  }

  setDriver(route) {
    if (route.type === 'driver') {
      this.driver = {
        name: this.translate.instant('common.you'),
        selfie_url: this.user.user.getValue().selfie_url,
      };
    } else {
      this.driver = route.matched_driver_routes[0].user;
    }
  }

  setPassengers(route) {
    this.passengers = route.matched_rider_routes.map(
      (passenger) => passenger.user
    );
    if (route.type !== 'driver') {
      this.passengers.push({
        name: this.translate.instant('common.you'),
        selfie_url: this.user.user.getValue().selfie_url,
      });
    }
  }

  setStatus() {
    if (this.route?.data?.status === 'finished') {
      this.routeStatus = 'Completado';
    } else {
      if (dayjs().isAfter(dayjs(this.route?.endTime))) {
        this.routeStatus = 'No completado';
      } else {
        this.routeStatus = 'Pendiente';
      }
    }
  }

  back() {
    this.backToCalendar.emit();
  }

  navigateToEventDetail(event) {
    this.router.navigate(['/route-details-map', event.data.id]);
  }
}
