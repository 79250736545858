import { Injectable } from '@angular/core';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  permissions = new Subject<'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'>()
  token = new Subject<string>()

  constructor() {
    FirebaseMessaging.addListener("notificationReceived", (event) => {
      console.log("notificationReceived: ", { event });
    });
    FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
      console.log("notificationActionPerformed: ", { event });
    });
  }

  initializeService = () => {
    this.permissions.subscribe(permission => {
      if (permission === 'prompt' || permission === 'prompt-with-rationale') {
        this.requestPermissions()
      } else if (permission === 'denied') {
        console.warn("Permiso de notificaciones denegado")
      } else {
        this.getToken()
      }
    })

    this.checkPermissions()
  }

  checkPermissions = async () => {
    const { receive } = await FirebaseMessaging.checkPermissions();
    this.permissions.next(receive)
  };

  requestPermissions = async () => {
    const { receive } = await FirebaseMessaging.requestPermissions();
    this.permissions.next(receive)
  };

  getToken = async () => {
    const { token } = await FirebaseMessaging.getToken()
    console.warn(token)
    this.token.next(token)
  }
}
