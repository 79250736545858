import { Injectable } from '@angular/core';
import { environment } from '@envs';

declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher;

  constructor() { }

  initialize() {
    this.pusher = new Pusher(environment.pusherKey, {
      cluster: "eu",
      userAuthentication: {
        transport: "jsonp",
        endpoint: `https://backend.drivers.waiis.eco/broadcasting/auth`
      }
    });
  }

  signin() {
    this.pusher.signin()
  }
}
