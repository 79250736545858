import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'dayClass',
  pure: true,
})
export class DayClassPipe implements PipeTransform {
  transform(date: any, today: any): string {
    let done = false;
    let notDone = false;
    let pending = false;

    for (const event of date.events) {
      const isBefore = dayjs(event.endTime).isBefore(today);

      if (!isBefore) {
        pending = true;
      } else if (!event.data.status || event.data.status === 'created') {
        notDone = true;
      } else if (event.data.status === 'finished') {
        done = true;
      }
    }

    if (pending) {
      if (notDone) {
        return 'left-red right-gray';
      } else if (done) {
        return 'left-primary right-gray';
      }
      return 'left-gray right-gray';
    }

    if (notDone) {
      return done ? 'left-red right-primary' : 'left-red right-red';
    }

    if (done) {
      return 'left-primary right-primary';
    }

    return '';
  }
}
