import { Component, OnInit } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { formatPrice, launchApp } from '@app/utils';
import { Platform } from '@ionic/angular';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { GoogleMapService } from '@services/google-map/google-map.service';
import { ResumenRentService } from '@services/modals/resumen-rent/resumen-rent.service';
import { RouteService } from '@services/route/route.service';

@Component({
  selector: 'app-resumen-rent',
  templateUrl: './resumen-rent.component.html',
})
export class ResumenRentComponent implements OnInit {
  activo = null
  address = ""
  vehicle
  origen

  constructor(
    private geocoder: MapGeocoder,
    private analytics: AnalyticsService,
    private platform: Platform,
    private resumenRent: ResumenRentService,
    private route: RouteService,
    private map: GoogleMapService
  ) {
    this.route.selectedActivo.subscribe(async activo => this.activo = activo)
  }

  async ngOnInit() {
    if (this.activo.familia.name === "moto") {
      this.vehicle = "moto_sharing.svg"
    }

    if (this.activo.data.lat && this.activo.data.lng) {
      await this.setAddress(this.activo.data)
    }
    
    this.route.origen.subscribe(origen => {
      this.origen = origen?.nombre
    })
  }

  close() {
    this.resumenRent.dismiss()
    this.route.selectActivo(null)
    this.map.resetPath()
  }

  openDeeplink() {
    this.analytics.logEvent(
      `abrir_${this.activo.operador}`,
      {
        familia: this.activo.familia,
        operador: this.activo.operador,
      }
    )

    if (this.activo.operador.name === 'cooltra') {
      window.open(this.activo.deeplink)
    } else {
      launchApp(this.activo.operador, this.platform.platforms())
    }
  }

  extractAddress(address) {
    const street = address.find(address_component => {
      return address_component.types.includes("route")
    })?.long_name

    const street_number = address.find(address_component => {
      return address_component.types.includes("street_number")
    })?.long_name

    return {street, street_number}
  }
  
  formatPrecio() {
    if (this.activo.estimated && this.activo.familia === 'taxi') {
      return formatPrice(this.activo.precio, 3);
    }

    return formatPrice(this.activo.precio);
  }

  async setAddress({lat, lng}) {
    const latLng = { lat, lng }

    return this.geocoder
      .geocode({ location: latLng })
      .forEach(res => {
        if (res.results.length > 0) {
          let address = {street: undefined, street_number: undefined}
          let i = 0

          while (!address.street && i <= res.results.length) {
            i++
            address = this.extractAddress(res.results[i].address_components)
          }

          this.address = `${address.street}, ${address.street_number}`
          return
        }
      })
  }

  toLowerCase(string: string) {
    return string.toLowerCase()
  }
}
