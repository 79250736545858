import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cardsIcons } from '@app/constants';
import Activo from '@app/models/Activo';
import Ubicacion from '@app/models/Ubicacion';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ModalsService } from '@services/modals/modals.service';
import { UtilsService } from '@services/utils/utils.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-resumen-taxi',
  templateUrl: './resumen-taxi.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResumenTaxiComponent implements OnInit {
  @Input() asset: Activo
  @Input() selectedCard?
  @Input() origen: Ubicacion
  @Input() destino: Ubicacion
  @Input() balance: number
  @Input() card: number
  isAuthenticated: boolean
  @Output() applyCoupon? = new EventEmitter()

  coupon: string = ""
  couponFocused: boolean = false

  isAuthSubscription: Subscription

  cardsIcons = cardsIcons

  constructor(
    private utilsService: UtilsService,
    private modalsService: ModalsService,
    private auth: AuthenticationService,
    private ref: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.isAuthSubscription = this.auth.isAuthenticated.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated
    })
  }

  applyCouponHandler() {
    this.applyCoupon.emit(this.coupon)
  }

  focusCoupon() {
    this.couponFocused = true
    this.ref.detectChanges()
  }

  blurCoupon() {
    this.couponFocused = false
    this.ref.detectChanges()
  }

  ngOnDestroy() {
    this.isAuthSubscription.unsubscribe()
  }

  openPaymentSelectorModal() {
    this.modalsService.presentModal('modalPayment')
  }

  formatPrice = (precio) => {
    return this.utilsService.formatPrice(precio)
  }

  formatTime = (tiempo) => {
    return this.utilsService.formatTime(tiempo)
  }
}
