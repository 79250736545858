import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-info',
  templateUrl: './driver-info.component.html',
})
export class DriverInfoComponent implements OnInit {
  @Input() trip

  constructor() { }

  ngOnInit() {}

  toLowercaseText(text = "") {
    return text.toLowerCase()
  }

  call() {
    window.open(`tel:${this.trip.extras.telcond}`)
  }
}
