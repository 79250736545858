import { Injectable } from '@angular/core';
import { doneTripStatus, tripStatuses } from '@app/constants';
import { calculateDistance, sortTripsByCreationDate } from '@app/utils';
import { Preferences } from '@capacitor/preferences';
import { environment } from '@envs';
import { AxiosService } from '@services/axios/axios.service';
import { GeolocationService } from '@services/geolocation/geolocation.service';
import { ModalsService } from '@services/modals/modals.service';
import { ToastService } from '@services/toast/toast-service';
import { TranslateService } from '@services/translate/translate.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViajesService {
  trips = new BehaviorSubject<any[]>([])
  currentTrip = new BehaviorSubject<any>(null)
  currentTripInterval

  pickUpPointTimeToChange = 10 * 1000 // Seconds to wait to change to 'pick up point' state

  pickUpPointTime

  tripToReport

  nextTripsPageUrl: string

  constructor(
    private axios: AxiosService,
    private toastService: ToastService,
    private geolocation: GeolocationService,
    private modalsService: ModalsService,
    private translateService: TranslateService
  ) {
    this.geolocation.location.subscribe(location => {
      const currentTrip = this.currentTrip.getValue()

      if (!currentTrip) {
        return tripStatuses.cancelled_by_system
      }

      if (currentTrip.state === tripStatuses.driver_to_origin) {
        const taxiLocation = new google.maps.LatLng(currentTrip.lat, currentTrip.lng)

        const userLocation = new google.maps.LatLng(location?.coords?.lat, location?.coords?.lng)
        const distanceTaxiToUser = calculateDistance(userLocation, taxiLocation)

        const trip = {
          ...currentTrip,
          state: tripStatuses.driver_arrived_origin
        }

        if (distanceTaxiToUser <= 10) {
          this.updateCurrentTrip(trip)
        }
      }
    })
  }

  async updateCurrentTrip(trip, keep = false) {
    if (!trip?.state) {
      return
    }

    if (!this.currentTripInterval && !doneTripStatus.includes(trip?.state)) {
      this.setCurrentTripInterval()
    }

    const lastStateCurrentTrip = this.currentTrip.getValue()

    if (
      this.currentTripInterval &&
      trip?.state === tripStatuses.searching_driver &&
      lastStateCurrentTrip.state !== trip?.state
    ) {
      this.modalsService.presentModal('modalTripStatus')
    }

    let state = trip.state

    const origin = JSON.parse((await Preferences.get({ key: 'origin' })).value)
    const destination = JSON.parse((await Preferences.get({ key: 'destination' })).value)

    const lastTripState: string = lastStateCurrentTrip?.id === trip.id ? lastStateCurrentTrip.state : tripStatuses.searching_driver

    if (
      (lastTripState === tripStatuses.our_no_drivers || lastTripState === tripStatuses.no_drivers) &&
      trip.state === tripStatuses.searching_driver && !keep
    ) {
      state = tripStatuses.no_drivers
    }

    this.currentTrip.next({
      ...trip,
      state,
      origin,
      destination
    })
  }

  async createPaymentIntent(activo, options: { coupon?: string, balance?: boolean }) {
    const bodyRequest = {
      "activo": activo,
      "coupon": options.coupon,
      "use_balance": options.balance
    };

    return (await this.axios.getInstance())
      .post('/viajes', bodyRequest)
        .then((response) => {
          this.updateCurrentTrip(response.data)
          return response.data
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            this.toastService.presentToast(error.response.data.message, 'error')
          } else {
            this.toastService.presentToast('Ha habido un error procesando la solicitud, por favor, inténtalo de nuevo más tarde', 'error')
          }
          throw error
        })
  }

  async verifyPayment(viaje: string, activo: string, payment: string, retry?: boolean) {
    const bodyRequest = {
      activo,
      stripe_payment_id: payment
    }

    return (await this.axios.getInstance())
      .post(`/viajes/${viaje}/verify`, bodyRequest)
        .then(response => {
          this.updateCurrentTrip(response.data)
        })
        .catch(error => {
          if (retry) {
            this.cancelTrip(viaje)
            return error
          } else {
            this.verifyPayment(viaje, activo, payment, true)
          }
        })
  }

  async getTrips(nextPage: boolean = false) {
    let url = "/viajes"
    if (nextPage) {
      url = this.nextTripsPageUrl
    }
    (await this.axios.getInstance())
      .get(url)
      .then(async response => {
        const trips = response.data.data.sort(sortTripsByCreationDate)
        if (!nextPage) {
          const lastTrip = trips[0]
          this.updateCurrentTrip(lastTrip)
          this.trips.next(trips)
        } else {
          this.trips.next([...this.trips.getValue(), ...trips])
        }
        this.nextTripsPageUrl = response.data.next_page_url
      })
  }

  async getTrip(tripId) {
    return (await this.axios.getInstance())
      .get(`/viajes/${tripId}`)
      .then(async response => {
        if (!doneTripStatus.includes(response.data.state)) {
          this.updateCurrentTrip(response.data)
        }
        return response.data
      })
  }

  async cancelTrip(tripId) {
    (await this.axios.getInstance())
      .post(`/viajes/${tripId}/cancel`)
      .then(async response => {
        this.updateCurrentTrip({
          ...response.data,
          state: response.data.state === "CANCELED" ? 'canceled' : response.data.state
        })
      })
      .catch(error => {
        this.toastService.presentToast(error.message ?? this.translateService.instant("trip_status.cancel_error"), 'error', { position: 'top' })
      })
  }

  setCurrentTripInterval() {
    this.currentTripInterval = setInterval(async () => {
      if (this.currentTrip) {
        const trip = await this.getTrip(this.currentTrip?.getValue().id)
        this.updateCurrentTrip(trip)
        if (doneTripStatus.includes(trip.state)) {
          clearInterval(this.currentTripInterval)
          this.currentTripInterval = null
        }
      }
    }, 5000);
  }

  async reportProblem(body) {
    if (environment.production) {
      (await this.axios.getInstance())
        .post('https://waiis.eco/api/app_report_trip', { body: JSON.stringify(body) })
        .then(() => Promise.resolve())
        .catch(() => Promise.reject())
    } else {
      console.warn(body)
    }
  }

  reset() {
    this.trips.next([])
    this.currentTrip.next(null)
    if (this.currentTripInterval) {
      clearInterval(this.currentTripInterval)
    }
  }
}
