import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserService } from '@services/user/user.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { Familia } from '@app/models/Familia';

const familias: Familia[] = ["taxi", "moto", "bici", "patinete", "transporte_publico"]

@Injectable({
  providedIn: 'root'
})
export class FamiliasService {
  familias: {
    nombre: Familia;
    style: {
      activo: string;
      inactivo: string;
    }
  }[] = [
    {
      nombre: 'taxi',
      style: {
        activo: 'fill-primary',
        inactivo: 'fill-white'
      }
    },
    {
      nombre: 'moto',
      style: {
        activo: 'fill-primary',
        inactivo: 'fill-white'
      }
    },
    {
      nombre: 'patinete',
      style: {
        activo: 'stroke-primary',
        inactivo: 'stroke-white'
      }
    },
    {
      nombre: 'bici',
      style: {
        activo: 'stroke-primary',
        inactivo: 'stroke-white'
      }
    },
    {
      nombre: 'transporte_publico',
      style: {
        activo: 'fill-primary',
        inactivo: 'fill-white'
      }
    },
  ];

  familiasActivas = new BehaviorSubject<Familia[]>(familias)

  constructor(
    private userService: UserService,
    private analytics: AnalyticsService
  ) {
    this.userService.preferences.subscribe((preferencias) => {
      if (preferencias) {
        let newFamiliasActivas = []
        preferencias?.familias.forEach(familia => {
          if (familia.estado) {
            newFamiliasActivas.push(familia.nombre)
          }
        })

        this.familiasActivas.next(newFamiliasActivas)
      } else {
        this.familiasActivas.next(familias) 
      }
    })
  }

  disableFamilia(familia: Familia) {
    const familiasActivas = this.familiasActivas.getValue()
    const activa = familiasActivas.indexOf(familia) !== -1

    if (activa) {
      const newFamiliasActivas = this.familiasActivas.getValue().filter(
        (family) => familia !== family
      );
      this.familiasActivas.next(newFamiliasActivas)
    }
  }

  enableFamilia(familia: Familia) {
    const familiasActivas = this.familiasActivas.getValue()
    const activa = familiasActivas.indexOf(familia) !== -1

    if (!activa) {
      const newFamiliasActivas = [...this.familiasActivas.getValue(), familia];
      this.familiasActivas.next(newFamiliasActivas)
    }
  }

  toggleFamilia(familia: Familia) {
    const familiasActivas = this.familiasActivas.getValue()
    const activa = familiasActivas.indexOf(familia) !== -1
    this.analytics.logEvent('seleccionar_familia', { familia, activa })

    let newFamiliasActivas

    if (activa) {
      newFamiliasActivas = this.familiasActivas.getValue().filter(
        (family) => familia !== family
      );
    } else {
      newFamiliasActivas = [...this.familiasActivas.getValue(), familia];
    }

    this.familiasActivas.next(newFamiliasActivas)
  }
}
