import { Injectable } from '@angular/core';
import { AppUpdate, AppUpdateAvailability, FlexibleUpdateState } from '@capawesome/capacitor-app-update';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreUpdatesService {
  updateState = new BehaviorSubject<number>(null)
  updateAvailable = new BehaviorSubject(null)

  constructor(
    private platform: Platform
  ) {
    AppUpdate.addListener('onFlexibleUpdateStateChange', (state: FlexibleUpdateState) => {
      this.updateState.next(state.installStatus)
    })
  }

  checkForUpdates = async () => {
    const updateAvailable = await this.getUpdateAvailable() === AppUpdateAvailability.UPDATE_AVAILABLE

    if (updateAvailable) {
      if (this.platform.is('android')) {
        this.startFlexibleUpdate()
      } else if (this.platform.is('ios')) {
        this.updateState.next(55)
      }
    }
  }

  getCurrentAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.currentVersion;
  };

  getAvailableAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.availableVersion;
  };

  openAppStore = async () => {
    await AppUpdate.openAppStore();
  };

  performImmediateUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate();
    }
  };

  startFlexibleUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.flexibleUpdateAllowed) {
      await AppUpdate.startFlexibleUpdate();
    }
  };

  completeFlexibleUpdate = async () => {
    await AppUpdate.completeFlexibleUpdate();
  };

  getUpdateAvailable = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.updateAvailability
  }
}
