import { Injectable } from '@angular/core';
import Ubicacion from '@app/models/Ubicacion';
import { AxiosService } from '@services/axios/axios.service';
import { BehaviorSubject } from 'rxjs';
import * as dayjs from 'dayjs';
import { ToastService } from '@services/toast/toast-service';
import { TranslateService } from '@services/translate/translate.service';
import { GeolocationService } from '@services/geolocation/geolocation.service';
import { Geolocation } from '@capacitor/geolocation';
import { LocationPermissionsService } from '@services/modals/locationPermissions/location-permissions.service';
import { Router } from '@angular/router';
import { UserService } from '@services/user/user.service';
import { Preferences } from '@capacitor/preferences';

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

class Routine {
  id: string;
  name: string;
  origin: {};
  destination: {};
  from_date: string;
  to_date?: string;
  type: 'driver' | 'rider' | 'both';
  selected_days: {
    departure_time: string;
    arrival_time: string;
    day: string;
    type: 'driver' | 'rider' | 'both';
    route_type: 'destination' | 'origin';
    available_seats: number;
    vehicle_id: string;
    price: number;
    recommended_price: number;
  }[];
}

class Route {
  arrival_time: string;
  available_seats: number;
  created_at: string;
  deleted_at: string;
  departure_time: string;
  destination_id: string;
  id: string;
  origin_id: string;
  pooling_routine_day_id: string;
  pooling_routine_id: string;
  start_date: string;
  type: string;
  updated_at: string;
  user_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class RoutinesService {
  routineConfig = new BehaviorSubject<Routine>(null);
  origen = new BehaviorSubject<Ubicacion | null>(null);
  destino = new BehaviorSubject<Ubicacion | null>(null);
  editingRoutineDirections = new BehaviorSubject<any>(null);

  routes = new BehaviorSubject<Route[]>([]);
  routines = new BehaviorSubject<Routine[]>([]);

  constructor(
    private axios: AxiosService,
    private toast: ToastService,
    private translate: TranslateService,
    private geolocation: GeolocationService,
    private locationPermissionsService: LocationPermissionsService,
    private router: Router,
    private user: UserService
  ) {
    Preferences.get({ key: 'userRoutines' }).then(routines => {
      if (routines) {
        this.routines.next(JSON.parse(routines.value))
      }
    })

    Preferences.get({ key: 'userRoutes' }).then(routes => {
      if (routes) {
        this.routes.next(JSON.parse(routes.value))
      }
    })

    this.routines.subscribe(routines => {
      Preferences.set({ key: 'userRoutines', value: JSON.stringify(routines) })
      routines.forEach(routine => this.addRoutineGeofences(routine))
    })

    this.routes.subscribe(routes => {
      Preferences.set({ key: 'userRoutes', value: JSON.stringify(routes) })
    })
  }

  async loadRoutineDetails(id) {
    if (this.routines.value.length === 0) {
      await this.getRoutines();
    }
    const routine = this.routines.value.find((routine) => routine.id === id);

    this.routineConfig.next(routine);
  }

  async getRoutineDirections() {
    const directions = new google.maps.DirectionsService();

    const route = await directions.route({
      origin: {
        lat: this.origen.getValue().coords.lat,
        lng: this.origen.getValue().coords.lng,
      },
      destination: {
        lat: this.destino.getValue().coords.lat,
        lng: this.destino.getValue().coords.lng,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    this.editingRoutineDirections.next(route);
  }

  setUbicacion(inputType: 'origen' | 'destino', ubicacion: Ubicacion) {
    this[inputType].next(ubicacion);
  }

  async loadRoutineForEdit(id) {
    if (this.routineConfig.value?.id !== id) {
      let routine = await this.getRoutine(id);

      routine = {
        id: routine.id,
        name: routine.name,
        origin: routine.origin,
        destination: routine.destination,
        from_date: routine.from_date,
        to_date: routine.to_date,
        type: routine.type,
        selected_days: routine.pooling_routine_days.map((day) => ({
          departure_time: day.departure_time,
          arrival_time: undefined,
          day: day.day,
          type: day.type,
          route_type: day.route_type,
          available_seats: day.available_seats,
          vehicle_id: day.vehicle_id,
          price: day.price,
          recommended_price: day.recommended_price,
        })),
      };

      const origin = {
        nombre: routine.origin.long_address,
        locality: routine.origin.locality,
        coords: {
          lat: parseFloat(routine.origin.lat),
          lng: parseFloat(routine.origin.lng),
        },
      };

      const destination = {
        nombre: routine.destination.long_address,
        locality: routine.destination.locality,
        coords: {
          lat: parseFloat(routine.destination.lat),
          lng: parseFloat(routine.destination.lng),
        },
      };

      this.routineConfig.next(routine);
      this.origen.next(origin);
      this.destino.next(destination);

      await this.getRoutineDirections();
    }

    return this.routineConfig.value;
  }

  setRoutineConfig(config) {
    let configObj: Routine = new Routine();

    configObj.name = config.name;

    configObj.origin = {
      lat: this.origen.value.coords.lat,
      lng: this.origen.value.coords.lng,
      locality: this.origen.value.locality,
      types: this.origen.value.types,
      long_address: this.origen.value.nombre_largo,
    };

    configObj.destination = {
      lat: this.destino.value.coords.lat,
      lng: this.destino.value.coords.lng,
      locality: this.destino.value.locality,
      types: this.destino.value.types,
      long_address: this.destino.value.nombre_largo,
    };

    configObj.selected_days = [];

    Object.keys(config.days.ida).forEach((item) => {
      const day = config.days.ida[item];
      configObj.selected_days.push({
        departure_time: day.exitTime,
        arrival_time: day.arrivalTime,
        day: weekdays[item],
        type: day.type,
        route_type: 'origin',
        available_seats: day.type === 'driver' ? day.capacity : undefined,
        vehicle_id: day.type === 'driver' ? day.vehicle?.id : undefined,
        price: day.type === 'driver' ? day.price : undefined,
        recommended_price: day.type === 'driver' ? day.recommended_price : undefined,
      });
    });

    Object.keys(config.days.vuelta).forEach((item) => {
      const day = config.days.vuelta[item];
      configObj.selected_days.push({
        departure_time: day.exitTime,
        arrival_time: day.arrivalTime,
        day: weekdays[item],
        type: day.type,
        route_type: 'destination',
        available_seats: day.type === 'driver' ? day.capacity : undefined,
        vehicle_id: day.type === 'driver' ? day.vehicle?.id : undefined,
        price: day.type === 'driver' ? day.price : undefined,
        recommended_price: day.type === 'driver' ? day.recommended_price : undefined,
      });
    });

    configObj.type = 'driver';

    this.routineConfig.next(configObj);
  }

  async publishRoutine(startDate, endDate) {
    this.routineConfig.next({
      ...this.routineConfig.value,
      from_date: startDate.split('T')[0],
      to_date: endDate
        ? endDate.split('T')[0]
        : dayjs().add(1, 'year').format('YYYY-MM-DD'),
    });

    Geolocation.checkPermissions().then((result) => {
      if (result.location !== 'granted') {
        this.locationPermissionsService.openModal();
      }
    });

    return (await this.axios.getInstance()).post(
      '/pooling/routines',
      this.routineConfig.value
    ).then(() => {
      this.user.getCalendarEvents();
      this.getRoutines();
      this.getRoutes();
    })
  }

  async updateRoutine(id) {
    return (await this.axios.getInstance()).put(
      `/pooling/routines/${id}`,
      this.routineConfig.value
    ).then(response => {
      const routines = this.routines.value.map((routine) => {
        if (routine.id === id) {
          return response.data;
        } else {
          return routine;
        }
      });
      this.routines.next(routines);

      this.user.getCalendarEvents();
    })
  }

  async getRoutes() {
    const start_date = dayjs().startOf('day').format('YYYY-MM-DD');
    const end_date = dayjs().add(7, 'day').format('YYYY-MM-DD');

    return (await this.axios.getInstance())
      .get(
        `/pooling/routes?append=price&start_date=${start_date}&end_date=${end_date}&with=requesting_coincidences,matched_coincidences`
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          this.geolocation.checkPermissions();
        }
        this.routes.next(response.data.data);
      });
  }

  addRoutineGeofences(routine) {
    const origin = routine.origin
    const destination = routine.destination

    this.geolocation.addGeofence({
      identifier: `${origin.lat},${origin.lng}-100`,
      latitude: origin.lat,
      longitude: origin.lng,
      radius: 100
    });

    this.geolocation.addGeofence({
      identifier: `${origin.lat},${origin.lng}-150`,
      latitude: origin.lat,
      longitude: origin.lng,
      radius: 150
    });

    this.addSatelliteGeofences(origin);

    this.geolocation.addGeofence({
      identifier: `${destination.lat},${destination.lng}-100`,
      latitude: destination.lat,
      longitude: destination.lng,
      radius: 100
    })

    this.geolocation.addGeofence({
      identifier: `${destination.lat},${destination.lng}-150`,
      latitude: destination.lat,
      longitude: destination.lng,
      radius: 150
    })

    this.addSatelliteGeofences(destination);
  }

  addSatelliteGeofences(coords) {
    for (let i = 0; i < 4; i++) {
      const r_earth = 6371e3;
      let dy = 0;
      let dx = 0;

      if (i === 0) {
        dy = 200;
      } else if (i === 1) {
        dy = -200;
      } else if (i === 2) {
        dx = 200;
      } else {
        dx = -200;
      }

      const newLatitude  = coords.lat  + (dy / r_earth) * (180 / Math.PI);
      const newLongitude = coords.lng + (dx / r_earth) * (180 / Math.PI) / Math.cos(coords.lat * Math.PI/180);

      this.geolocation.addGeofence({
        identifier: `${newLatitude},${newLongitude}`,
        latitude: newLatitude,
        longitude: newLongitude
      });
    }
  }

  async getRoutines() {
    return (await this.axios.getInstance())
      .get('/pooling/routines?with=destination,origin')
      .then((response) => {
        this.routines.next(response.data.data);
      });
  }

  async getRoutineById(id) {
    if (this.routines.value.length === 0) {
      await this.getRoutines();
    }
    const routine = this.routines.value.find((routine) => routine.id === id);

    return routine;
  }

  async getRouteById(id) {
    let route: any = this.routes.value.find((route) => route.id === id);
    let withParams: string = 'origin,destination'
    let withParam: string;
    if (!route) {
      withParams = `${withParams},matched_driver_routes..user,matched_rider_routes..user`
    } else {
      withParam = route.type === 'driver' ? 'matched_rider_routes' : 'matched_driver_routes';
      withParams = `${withParams},${withParam}..user`
    }

    if (!route || !route[withParam]) {
      route = (await this.axios.getInstance())
        .get(`/pooling/routes/${id}?with=${withParams}`)
        .then((response) => {
          return response.data;
        });
    }

    return route;
  }

  async getRoutine(id) {
    return (await this.axios.getInstance())
      .get(`/pooling/routines/${id}?with=origin,destination`)
      .then((response) => {
        return response.data;
      });
  }

  async updatePoolingRoutineDay(routineId, dayId, data) {
    return (await this.axios.getInstance())
      .put(`/pooling/routines/${routineId}/days/${dayId}`, data)
      .then((response) => {
        return response.data;
      });
  }

  async getRecommendedPrice(fuelType, distance, duration) {
    return (await this.axios.getInstance())
      .get(
        `/fuel-types/${fuelType}/recommended-price?distance=${distance}&duration=${duration}`
      )
      .then((response) => {
        return response.data.recommended_price;
      });
  }

  async deleteRoute(id) {
    return (await this.axios.getInstance())
      .delete(`/pooling/routes/${id}`)
      .then(() => {
        const routes = this.routes.value.filter((route) => route.id !== id);
        this.routes.next(routes);
        this.toast.presentToast(
          this.translate.instant('my_waiis.agenda.delete_success'),
          'info'
        );
        this.user.getCalendarEvents();
      })
      .catch((error) => {
        if (error.response.status === 500) {
          this.toast.presentToast(
            this.translate.instant('errors.routes_delete_generic_error'),
            'error'
          );
        } else {
          this.toast.presentToast(
            this.translate.instant(`errors.${error.response.data.error_slug}`),
            'error'
          );
        }
      });
  }

  async getUserLocations(from, to, limit = 100) {
    return (await this.axios.getInstance())
      .get(`/user-locations/journey?from_date=${from}&to_date=${to}&type=array&limit=${limit}`)
      .then((response) => {
        return response.data
      })
  }

  async deleteRoutine(id, date) {
    const routine = this.routines.value.find((routine) => routine.id === id);

    const to_date = dayjs(date).subtract(1, 'day').format('YYYY-MM-DD')

    return (await this.axios.getInstance())
      .put(`/pooling/routines/${id}`, { ...routine, to_date })
      .then((response) => {
        const routes = this.routes.value.filter(
          (route) =>
            route.pooling_routine_id !== id ||
            dayjs(route.start_date).isBefore(dayjs(date))
        );
        this.routes.next(routes);

        const routines = this.routines.value.map((routine) => {
          if (routine.id === id) {
            return response.data;
          } else {
            return routine;
          }
        });
        this.routines.next(routines);
        this.user.getCalendarEvents();

        this.router.navigate(['/'], { replaceUrl: true })

        this.toast.presentToast(
          this.translate.instant('my_waiis.agenda.delete_routine_success'),
          'info'
        );
      })
      .catch((_) => {
        this.toast.presentToast(
          this.translate.instant('errors.routines_delete_generic_error'),
          'error'
        );
      });
  }

  reset() {
    this.routines.next([]);
    this.routes.next([]);
    this.routineConfig.next(null);
    this.origen.next(null);
    this.destino.next(null);
    this.editingRoutineDirections.next(null);

    Preferences.remove({ key: 'userRoutines' });
    Preferences.remove({ key: 'userRoutes' });
  }
}
