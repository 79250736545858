const chinchetasConInfo = ["cooltra", "bicing", "bizi", "bicimad", "goto"]

const tripStatuses = {
  created: 'created',
  searching_driver: 'searching_driver',
  cancelled_by_system: 'cancelled_by_system',
  cancelled_by_rider: 'cancelled_by_rider',
  cancelled_by_driver: 'cancelled_by_driver',
  driver_to_origin: 'driver_to_origin',
  driver_arrived_origin: 'driver_arrived_origin',
  to_destination: 'to_destination',
  arrived_to_destination: 'arrived_to_destination',
  finished: 'finished',
  reserved: 'reserved',
  no_drivers: 'no_drivers',
  our_no_drivers: 'our_no_drivers'
}

const doneTripStatus = [
  tripStatuses.finished,
  tripStatuses.cancelled_by_system,
  tripStatuses.cancelled_by_rider,
  tripStatuses.cancelled_by_driver
]

const operatorsUrlSchemes = {
  'bolt': {
    android: 'ee.mtakso.client',
    iphone: 'bolt://'
  },
  'seatmo': {
    android: 'seat.code.emobility.seatmo',
    iphone: 'seat.code.emobility.seatmo://'
  },
  'cabify': {
    android: 'com.cabify.rider',
    iphone: 'cabify://'
  },
  'cooltra': {
    android: 'com.mobime.ecooltra',
    iphone: 'cooltra://'
  },
  'acciona': {
    android: 'com.acciona.mobility.app',
    iphone: 'com.acciona.mobility.app://'
  },
  'ambtmb': {
    android: 'cat.bcn.smoubcn',
    iphone: 'cat.bcn.smoubcn://'

  },
  'bicing': {
    android: 'cat.bcn.smoubcn',
    iphone: 'cat.bcn.smoubcn://'
  },
  'taxi_zaragoza': {
    android: 'es.nitax.ZGZsidustaxi4you',
    iphone: 'es.nitax.ZGZsidustaxi4you://'
  },
  'bizi': {
    android: 'com.zaragoza.bizi',
    iphone: 'iOSBiziApp://'
  },
  'lime': {
    android: 'com.limebike',
    iphone: 'limebike://'
  },
  'bicimad': {
    android: 'com.emtmadrid.bicimad.gestion2',
    iphone: 'com.emtmadrid.bicimad.gestion2'
  },
  'uber': {
    android: 'com.ubercab',
    iphone: 'uber://'
  },
  'metromadrid': {
    iphone: '',
    android: ''
  },
  'goto': {
    iphone: 'https://gotoglobal.onelink.me/zrUS/anmjz2t1',
    android: 'https://gotoglobal.onelink.me/zrUS/anmjz2t1'
  }
}

const operatorsAppStores = {
  'bolt': {
    'iphone': 'https://apps.apple.com/es/app/bolt-solicita-viajes-24-7/id675033630',
    'android': 'https://play.google.com/store/apps/details?id=ee.mtakso.client',
  },
  'seatmo': {
    'iphone': 'https://apps.apple.com/es/app/seat-m%C3%B3tosharing/id1497902704',
    'android': 'https://play.google.com/store/apps/details?id=seat.code.emobility.seatmo'
  },
  'cabify': {
    'iphone': 'https://apps.apple.com/es/app/cabify/id476087442',
    'android': 'https://play.google.com/store/apps/details?id=com.cabify.rider'
  },
  'cooltra': {
    'iphone': 'https://apps.apple.com/es/app/cooltra-motosharing-scooter/id1083424977',
    'android': 'https://play.google.com/store/apps/details?id=com.mobime.ecooltra'
  },
  'acciona': {
    'iphone': 'https://apps.apple.com/es/app/acciona-motosharing-movilidad/id1431664935',
    'android': 'https://play.google.com/store/apps/details?id=com.acciona.mobility.app',
  },
  'ambtmb': {
    'iphone': 'https://apps.apple.com/es/app/smou/id1439898721',
    'android': 'https://play.google.com/store/apps/details?id=cat.bcn.smoubcn',
  },
  'bicing': {
    'iphone': 'https://apps.apple.com/es/app/smou/id1439898721',
    'android': 'https://play.google.com/store/apps/details?id=cat.bcn.smoubcn',
  },
  'taxi_zaragoza': {
    'iphone': 'https://apps.apple.com/es/app/taxi-zaragoza/id1161374423',
    'android': 'https://play.google.com/store/apps/details?id=es.nitax.ZGZsidustaxi4you',
  },
  'bizi': {
    'iphone': 'https://apps.apple.com/us/app/bizi-zaragoza/id1529985771',
    'android': 'https://play.google.com/store/apps/details?id=com.zaragoza.bizi',
  },
  'lime': {
    'iphone': 'https://apps.apple.com/us/app/lime-ridegreen/id1199780189',
    'android': 'https://play.google.com/store/apps/details?id=com.limebike',
  },
  'bicimad': {
    'iphone': 'https://apps.apple.com/us/app/bicimad-emt-madrid/id1263402487',
    'android': 'https://play.google.com/store/apps/details?id=com.emtmadrid.bicimad.gestion2',
  },
  'uber': {
    'iphone': 'https://apps.apple.com/us/app/uber-request-a-ride/id368677368',
    'android': 'https://play.google.com/store/apps/details?id=com.ubercab',
  },
  'goto': {
    'iphone': 'https://gotoglobal.onelink.me/zrUS/anmjz2t1',
    'android': 'https://gotoglobal.onelink.me/zrUS/anmjz2t1'
  }
}

const cardsIcons = ['Apple', 'Google', 'MasterCard', 'Visa']

const addressTypes = ['home', 'work', 'restaurant', 'supermarket', 'gym', 'airport', 'hospital', 'hotel', 'park', 'other']

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export {
  doneTripStatus,
  chinchetasConInfo,
  tripStatuses,
  operatorsUrlSchemes,
  operatorsAppStores,
  cardsIcons,
  addressTypes,
  weekdays
}
