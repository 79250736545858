import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from "@capacitor-firebase/analytics"
import { environment } from '@envs';

interface User {
  id: string;
  name: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor() { }

  initializeAnalytics(user: User) {
    if (environment.production) {
      this.setUser(user)
    }
  }

  setUser(user: User) {
    FirebaseAnalytics.setUserId({
      userId: user.id,
    })

    FirebaseAnalytics.setUserProperty({
      key: 'user_name',
      value: user.name
    })

    FirebaseAnalytics.setUserProperty({
      key: 'user_email',
      value: user.email
    })
  }

  logEvent(name: string, params) {
    if (environment.production) {
      FirebaseAnalytics.logEvent({ name, params })
    } else {
      console.info('%clog event', 'color: white; background-color: #61dbfb', name, params)
    }
  }

  setCurrentScreen(screenName: string) {
    if (environment.production) {
      FirebaseAnalytics.setCurrentScreen({ screenName })
    }
  }
}
