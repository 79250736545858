import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResumenCarpoolingService } from '@services/modals/resumen-carpooling/resumen-carpooling.service';

@Component({
  selector: 'app-resumen-carpooling',
  templateUrl: './resumen-carpooling.component.html',
  styleUrls: ['./resumen-carpooling.component.scss'],
})
export class ResumenCarpoolingComponent implements OnInit {
  passengers: number = 1

  constructor(
    private router: Router,
    private resumenCarpoolingService: ResumenCarpoolingService
  ) { }

  ngOnInit() {}

  increasePassengers() {
    if (this.passengers === 3) {
      return
    }
    this.passengers += 1
  }

  decreasePassengers() {
    if (this.passengers === 1) {
      return
    }
    this.passengers -= 1
  }

  clickButton() {
    this.resumenCarpoolingService.dismiss(true)
    this.router.navigate(['/pooling-wip'])
  }
}
