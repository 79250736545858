import { Component, Input, OnInit } from '@angular/core';
import Activo from '@app/models/Activo';
import Ubicacion from '@app/models/Ubicacion';
import { RouteService } from '@services/route/route.service';
import { UtilsService } from '@services/utils/utils.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-resumen-transporte-publico',
  templateUrl: './resumen-transporte-publico.component.html',
  styleUrls: ['./resumen-transporte-publico.component.scss'],
})
export class ResumenTransportePublicoComponent implements OnInit {
  @Input() asset: any
  @Input() flatternSteps: any[]

  origin: Ubicacion
  destination: Ubicacion

  steps: any[] = []
  arrival_time
  departure_time

  destinoSubscription: Subscription
  origenSubscription: Subscription

  constructor(
    private utilsService: UtilsService,
    private routeService: RouteService
  ) { }

  ngOnInit() {
    this.destinoSubscription = this.routeService.destino.subscribe(destino => this.destination = destino)
    this.origenSubscription = this.routeService.origen.subscribe(origen => this.origin = origen)

    this.steps = []

    const dir = [...this.asset.directions.rider_to_origin, this.asset.directions.origin_to_destination]

      dir.map(directions => {
      directions.routes.map(route => {
        route.legs.map(leg => {
          this.arrival_time = leg.arrival_time.text
          this.departure_time = leg.departure_time.text

          leg.steps.forEach((step, index) => {
            const prevStep = leg.steps[index - 1]
            const nextStep = leg.steps[index + 1]

            switch (step.travel_mode) {
              case google.maps.TravelMode.WALKING:
                if (
                  prevStep?.travel_mode === google.maps.TravelMode.TRANSIT &&
                  nextStep?.travel_mode === google.maps.TravelMode.TRANSIT &&
                  step.distance.value === 0
                ) {
                  this.steps.push({
                    text: `transbordo`
                  })
                } else {
                  this.steps.push({
                    travelMode: step.travel_mode,
                    duration: step.duration.text,
                    distance: step.distance.text
                  })
                }
                break
              case google.maps.TravelMode.TRANSIT:
                let entrance
                let exit

                if (prevStep?.travel_mode === google.maps.TravelMode.WALKING) {
                  const extractedInfo = prevStep.steps[prevStep.steps.length -1]?.html_instructions
                  entrance = extractedInfo ? this.extractLocationFromHTML(extractedInfo) : null
                }

                if (nextStep?.travel_mode === google.maps.TravelMode.WALKING) {
                  const extractedInfo = nextStep.steps[0]?.html_instructions
                  exit = extractedInfo ? this.extractLocationFromHTML(extractedInfo) : null
                }

                this.steps.push({
                  travelMode: step.travel_mode,
                  duration: step.duration.text,
                  departure_stop: step.transit_details.departure_stop.name,
                  arrival_stop: step.transit_details.arrival_stop.name,
                  departure_time: step.transit_details.departure_time.text,
                  arrival_time: step.transit_details.arrival_time.text,
                  line: {
                    name: step.transit_details.line.short_name,
                    color: step.transit_details.line.color,
                    text_color: step.transit_details.line.text_color,
                    icon: step.transit_details.line.vehicle.local_icon ?? step.transit_details.line.vehicle.icon,
                  },
                  headsign: step.transit_details.headsign,
                  stops: step.transit_details.num_stops,
                  entrance,
                  exit
                })
                break
            }
          })
        })
      })
    })
  }

  ngOnDestroy() {
    this.origenSubscription.unsubscribe()
    this.destinoSubscription.unsubscribe()
  }


  extractLocationFromHTML(html: string) {
    try {
      const el = document.createElement('div')
      el.innerHTML = html
  
      const location = el.getElementsByClassName('location')[0].innerHTML
      return location
    } catch (_) {
      return null
    }
  }

  formatPrice(price: number) {
    this.utilsService.formatPrice(price)
  }
}
