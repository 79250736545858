import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

export const breakpointsPx = [110, 296]

@Injectable({
  providedIn: 'root'
})
export class MyWaiisService {
  modal
  open = new BehaviorSubject<boolean>(false)
  breakpoints = [breakpointsPx[0] / this.platform.height(), 1]

  currentBreakpoint = new BehaviorSubject<number>(this.breakpoints[0])

  prevRoute

  private isSideMenuVisible: boolean = false;
  private isBuscadorVisible: boolean = false;
  private isInfoActivoVisible: boolean = false;
  private isTripStatusVisible: boolean = false;
  private isComparadorVisible: boolean = false;
  private isResumenVisible: boolean = false;
  private isCarpoolingResultsVisible: boolean = false;
  private isResumenRentVisible: boolean = false;

  shouldBeVisible = new BehaviorSubject<boolean>(true);

  constructor(
    private platform: Platform,
    private router: Router
  ) {
    this.router.events.subscribe((navigationEvent: NavigationEnd) => {
      if (navigationEvent instanceof NavigationEnd && navigationEvent.url !== this.prevRoute) {
        this.prevRoute = navigationEvent.url
        this.checkIfShouldBeVisible()
      }
    })
  }

  checkIfShouldBeVisible() {
    const shouldBeVisible = (
      this.router.url === "/" &&
      !this.isSideMenuVisible &&
      !this.isBuscadorVisible &&
      !this.isInfoActivoVisible &&
      !this.isTripStatusVisible &&
      !this.isComparadorVisible &&
      !this.isResumenVisible &&
      !this.isCarpoolingResultsVisible &&
      !this.isResumenRentVisible &&
      !this.shouldBeVisible.value
    );

    this.shouldBeVisible.next(shouldBeVisible)
  }

  setBuscadorVisible(value: boolean) {
    this.isBuscadorVisible = value;
    this.checkIfShouldBeVisible();
  }

  setSideMenuVisible(value: boolean) {
    this.isSideMenuVisible = value;
    this.checkIfShouldBeVisible();
  }

  setInfoActivoVisible(value: boolean) {
    this.isInfoActivoVisible = value;
    this.checkIfShouldBeVisible();
  }

  setTripStatusVisible(value: boolean) {
    this.isTripStatusVisible = value;
    this.checkIfShouldBeVisible();
  }

  setComparadorVisible(value: boolean) {
    this.isComparadorVisible = value;
    this.checkIfShouldBeVisible();
  }

  setResumenVisible(value: boolean) {
    this.isResumenVisible = value;
    this.checkIfShouldBeVisible();
  }

  setCarpoolingVisible(value: boolean) {
    this.isCarpoolingResultsVisible = value;
    this.checkIfShouldBeVisible();
  }

  setResumenRentVisible(value: boolean) {
    this.isResumenRentVisible = value;
    this.checkIfShouldBeVisible();
  }

  changeBreakpoint() {
    if (this.currentBreakpoint.value === 0) {
      this.currentBreakpoint.next(1)
    } else {
      this.currentBreakpoint.next(0)
    }
  }
}
