import { Component, Input, OnInit } from '@angular/core';
import { formatPrice } from '@app/utils';
import { UtilsService } from '@services/utils/utils.service';

@Component({
  selector: 'app-resumen-info',
  templateUrl: './resumen-info.component.html',
  styleUrls: ['./resumen-info.component.scss'],
})
export class ResumenInfoComponent implements OnInit {
  @Input() distance
  @Input() price
  @Input() duration
  @Input() estimated
  @Input() balance?: number
  @Input() card?: number

  constructor(
    private utilsService: UtilsService
  ) { }

  get formattedDistance() {
    return this.utilsService.formatDistance(this.distance)
  }

  get formattedPrice() {
    return formatPrice(this.price, this.estimated ? 2 : null)
  }

  get formattedBalance() {
    return formatPrice(this.balance)
  }

  get formattedCard() {
    return formatPrice(this.card)
  }

  get formattedDuration() {
    return this.utilsService.formatTime(this.duration)
  }

  ngOnInit() {}
}
