import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ComparadorService } from '../comparador/comparador.service';
import { MyWaiisService } from '../my-waiis/my-waiis.service';
import { ResumenCarpoolingComponent } from '@components/resumen-carpooling/resumen-carpooling.component';

@Injectable({
  providedIn: 'root'
})
export class ResumenCarpoolingService {
  modal
  open = new BehaviorSubject<boolean>(false)
  constructor(
    private modalCtrl: ModalController,
    private comparador: ComparadorService,
    private myWaiis: MyWaiisService,
    private platform: Platform
  ) { }

  async openModal() {
    this.myWaiis.setResumenCarpoolingVisible(true)
    this.comparador.dismiss()
    this.modal = await this.modalCtrl.create({
      component: ResumenCarpoolingComponent,
      breakpoints: [0, 0.4],
      initialBreakpoint: 0.4,
      backdropDismiss: false,
      showBackdrop: false,
      handle: false,
      id: "modalResumenCarpooling",
      cssClass: this.platform.is("android") && "android"
    });
    this.modal.present();
    this.open.next(true)

    const { data } = await this.modal.onWillDismiss();
    this.open.next(false)
    if (!data.avoidComparator) {
      this.comparador.openModal()
    }
    this.myWaiis.setResumenCarpoolingVisible(false)
  }

  dismiss(avoidComparator = false) {
    this.modal.dismiss({avoidComparator})
  }
}
