import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Camera, CameraDirection, CameraResultType } from '@capacitor/camera';
import InputFile, { Document } from '@app/models/InputFile';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnInit {
  @Input() inputFile: InputFile
  @Input() inputFiles: Document
  @Output() inputFileChange = new EventEmitter<InputFile>()
  @Output() inputFilesChange = new EventEmitter()

  constructor() { }

  ngOnInit() { }

  async onClick() {
    if (this.inputFiles) {
      await this.takePicture(this.inputFiles.front, {
        promptLabelHeader: 'Parte delantera'
      })
      await this.takePicture(this.inputFiles.back, {
        promptLabelHeader: 'Parte trasera'
      })
    }

    if (this.inputFile) {
      this.takePicture(this.inputFile, {
        direction: CameraDirection.Front,
      })
    }
  }

  async getFile(base64Image) {
    let file;
    await fetch(base64Image)
      .then(res => res.blob())
      .then(blob => {
        file = new File([blob], "image", { type: "image/jpeg" })
      })

    return file
  }

  takePicture(input, options?) {
    return Camera.getPhoto({
      quality: 20,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      presentationStyle: 'popover',
      saveToGallery: false,
      ...options
    }).then(async (image) => {
      input.image = image.dataUrl
      input.file = await this.getFile(image.dataUrl)
    })
  };

  removeInputFile(event: any) {
    event.preventDefault()
    event.stopPropagation()

    this.inputFile.file = null
    this.inputFile.image = null
  }
}
