import { BehaviorSubject, Subject } from 'rxjs';

import Activo from '@app/models/Activo';
import { Injectable } from '@angular/core';
import Ubicacion from '@app/models/Ubicacion';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { ViajesService } from '@services/viajes/viajes.service';

function recursion(index, inputArray, outputArray) {
  if (index >= inputArray.length) {
    return;
  }
  if (Array.isArray(inputArray[index].steps)) {
    recursion(0, inputArray[index].steps, outputArray);
  } else if (inputArray[index].html_instructions) {
    outputArray.push(inputArray[index]);
  }

  recursion(index + 1, inputArray, outputArray);
}

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  selectedActivo = new BehaviorSubject<Activo>(null);

  origen = new BehaviorSubject<Ubicacion | null>(null);
  destino = new BehaviorSubject<Ubicacion | null>(null);
  userLocation = new BehaviorSubject<Ubicacion | null>(null);
  selectedOnMap = new BehaviorSubject<Ubicacion | null>(null);

  pathToprint = new Subject<any[]>();

  selectedStep = new Subject<Number>();

  shouldUpdate = {
    origen: new BehaviorSubject<boolean>(false),
    destino: new BehaviorSubject<boolean>(false),
  }

  constructor(
    private analytics: AnalyticsService,
    private viajes: ViajesService
  ) {  }

  setUbicacion(inputType: 'origen' | 'destino' | 'home' | 'work', ubicacion: Ubicacion, forceInputUpdate: boolean = false) {
    if (ubicacion) {
      this.analytics.logEvent(
        'set_ubicacion',
        {
          inputType,
          nombre: ubicacion.nombre,
          place_id: ubicacion.place_id,
          locality: ubicacion.locality,
          coords: JSON.stringify(ubicacion.coords)
        }
      )
    }
    this[inputType].next(ubicacion);

    if (forceInputUpdate) {
      this.shouldUpdate[inputType].next(true)
    }
  }

  mapClick(ubicacion: Ubicacion | null) {
    this.selectedOnMap.next(ubicacion)
  }

  async setFromCurrentTrip() {
    const currentTrip = this.viajes.currentTrip.getValue()
    const origin = currentTrip.origin
    const destination = currentTrip.destination

    this.setUbicacion('origen', origin, true)
    this.setUbicacion('destino', destination, true)
  }

  flatternSteps(inputArray: any[]): any[] {
    let outputArray = [];
    recursion(0, inputArray, outputArray);
    return outputArray;
  }

  flatternDirections(inputArray) {
    const directions = inputArray.reduce((accumulator, currentValue) => {
      accumulator.push(...currentValue.routes[0].legs)
      return accumulator
    }, [])

    return { routes: [{ legs: directions }] }
  }

  printPath(steps) {
    this.pathToprint.next(this.flatternSteps(steps));
  }

  selectStep(step) {
    this.selectedStep.next(step);
  }

  selectActivo(activo) {
    this.selectedActivo.next(activo);
  }

  switchRoute() {
    const destino = this.destino.getValue()
    const origen = this.origen.getValue()
    
    this.destino.next(null)

    this.origen.next(destino)
    this.destino.next(origen)

    this.shouldUpdate.destino.next(true)
    this.shouldUpdate.origen.next(true)
  }

  reset() {
    this.selectedActivo.next(null)
    this.origen.next(null)
    this.destino.next(null)
    this.userLocation.next(null)
    this.pathToprint.next(undefined)
    this.selectedStep.next(undefined)
  }
}
