import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type ModalName =
  'modalResumen' |
  'modalPayment' |
  'modalAddPaymentMethod' |
  'modalTripStatus' |
  'modalInfoActivo' |
  'modalSelectLocation' |
  'storeUpdatesModal'

@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  modalResumen = new BehaviorSubject<{ action: string; params: any[] }>(null)
  modalPayment = new BehaviorSubject<{ action: string; params: any[] }>(null)
  modalAddPaymentMethod = new BehaviorSubject<{ action: string; params: any[] }>(null)
  modalTripStatus = new BehaviorSubject<{ action: string; params: any[] }>(null)
  modalInfoActivo = new BehaviorSubject<{ action: string; params: any[] }>(null)
  modalSelectLocation = new BehaviorSubject<{ action: string; params: any[] }>(null)
  storeUpdatesModal = new BehaviorSubject<{ action: string; params: any[] }>(null)

  constructor() { }

  presentModal(modal: ModalName) {
    this[modal].next({
      action: 'present',
      params: [{ animate: true }]
    })
  }

  moveModalToBreak(modal: ModalName, breakPoint: 'bottom' | 'middle' | 'top') {
    this[modal].next({
      action: 'moveToBreak',
      params: [breakPoint]
    })
  }

  hideModal(modal: ModalName) {
    this[modal].next({
      action: 'hide',
      params: null
    })
  }

  destroyModal(modal: ModalName) {
    this[modal].next({
      action: 'destroy',
      params: [{ animate: true }]
    })
  }
}
