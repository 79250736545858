import { Injectable } from '@angular/core';
import { TranslateService as NgTranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService extends NgTranslateService {
  languageList = ["es", "en"]
  defaultLanguage = "es"
  initializeService() {
    this.addLangs(this.languageList)
    this.setDefaultLang(this.defaultLanguage)

    let currentLang = this.getCurrentLang()
    if (!currentLang || !this.languageList.includes(currentLang)) {
      currentLang = this.getBrowserLang()
      if (!this.languageList.includes(currentLang)) {
        currentLang = this.defaultLang
      }
      this.setLanguage(currentLang)
    }

    this.use(currentLang)
  }

  setLanguage(lang: string) {
    localStorage.setItem('lang', lang);
    this.use(lang);
  }

  getCurrentLang() {
    return localStorage.getItem('lang');
  }
}
