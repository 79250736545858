import { Injectable } from '@angular/core';
import User from '@app/models/User';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  constructor() {
    FirebaseAnalytics.setEnabled({
      enabled: true,
    });
  }

  userId: string

  setUser(user: User) {
    this.userId = user.id

    FirebaseAnalytics.setUserId({
      userId: user.id,
    })

    FirebaseAnalytics.setUserProperty({
      key: 'user_name',
      value: user.name
    })

    FirebaseAnalytics.setUserProperty({
      key: 'user_email',
      value: user.email
    })
  }

  log(name: string, params = {}) {
    FirebaseAnalytics.logEvent({ name, params: {...params, debug_mode: true, debug: true, userId: this.userId } })
  }
}
