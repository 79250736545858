import { Injectable } from '@angular/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OtaUpdatesService {
  latest
  current = new BehaviorSubject<any>(null)
  list

  constructor() { }

  async checkVersion() {
    this.latest = await CapacitorUpdater.getLatest()
    this.getCurrent()
    this.list = await CapacitorUpdater.list()

    if (this.latest.version !== this.current.getValue().version) {
      let versionInList = this.list.bundles.find(item => item.version === this.latest.version)
      if (!versionInList) {
        await CapacitorUpdater.download(this.latest)
      }

      versionInList = this.list.bundles.find(item => item.version === this.latest.version)

      CapacitorUpdater.set({ id: versionInList.id })
    }
  }

  async getCurrent() {
    const current = await CapacitorUpdater.current()
    this.current.next(current.bundle)
  }
}
