import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class TranslateHelper {
  private static injector: Injector;
  private static translateService: TranslateService;

  public static setInjector(injector: Injector) {
    this.injector = injector;
    this.translateService = this.injector.get(TranslateService);
  }

  public static instant(key: string | Array<string>, interpolateParams?: Object): string | any {
    return this.translateService.instant(key, interpolateParams);
  }

  public static get(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
    return this.translateService.get(key, interpolateParams);
  }

  public static getLang(): string {
    return this.translateService.currentLang || this.translateService.defaultLang;
  }
}