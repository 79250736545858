import { GeolocationService } from '@services/geolocation/geolocation.service'
import { Injectable } from '@angular/core'
import { RouteService } from '@services/route/route.service'
import Ubicacion from '@app/models/Ubicacion'
import { ViajesService } from '@services/viajes/viajes.service'
import { calculateDistance } from '@app/utils'
import { first } from 'rxjs/operators';
import { ModalsService } from '@services/modals/modals.service'
import { MapGeocoder } from '@angular/google-maps'
import { ComparadorService } from '@services/modals/comparador/comparador.service'
import { RoutinesService } from '@services/routines/routines.service'

const indicatorsColors = {
  selected: {
    active: '#013445',
    inactive: '#01344560'
  }
}

const dashedLine = {
  path: 'M 0,-1 0,4',
  strokeOpacity: 1,
  scale: 2
}

const dottedLine = {
  path: "M 0,-1 0,1",
  strokeOpacity: 1,
  scale: 2,
}

const distanciaEn5min = 5 / 60 * 5 * 1000 // Velocidad media humano andando = 5km/h

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {
  mapa
  userLocationDot
  userLocationDotRadius
  userLocation
  walkingRadius
  walkingRadiusMarker
  defaultPaddingBottom = 475
  paddingBottom: number = this.defaultPaddingBottom

  locationDotMarker = {
    path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
    fillColor: indicatorsColors.selected.active,
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: '#FFF',
    strokeOpacity: 1,
    scale: 0.5,
  }

  walkingRadiusMarkerIcon = {
    url: 'assets/icons/walking.png',
    scaledSize: new google.maps.Size(12, 20),
    anchor: new google.maps.Point(6, 40),
    labelOrigin: new google.maps.Point(6, 28)
  }

  polyline = []

  carpoolingPolylineOrigin = []
  carpoolingPolylineDestination = []
  routeDetailsPolyline

  selectedStep = null
  selectedPath = null

  markers = { origin: undefined, destiny: undefined, click: undefined, favorite: undefined }
  userMarkers = []
  origin
  destiny
  bounds = new google.maps.LatLngBounds()
  positionGeolocated = false

  currentTrip

  followUserLocation = true

  constructor(
    private geolocationService: GeolocationService,
    private routeService: RouteService,
    private viajesService: ViajesService,
    private modalsService: ModalsService,
    private geocoder: MapGeocoder,
    private comparador: ComparadorService,
    private routine: RoutinesService
  ) {
    this.comparador.currentBreakpoint.subscribe(currentBreakpoint => {
      this.paddingBottom = currentBreakpoint * (475 + 104)
      this.setBounds()
    })

    this.geolocationService.location.subscribe(
      (location: Ubicacion) => {
        if (location) {
          this.userLocation = location.coords
          this.updatePosition(location.coords)

          if (this.followUserLocation) {
            this.centerMap({ lat: this.userLocation.lat, lng: this.userLocation.lng })
          }
        }
      }
    )

    this.routeService.pathToprint.subscribe((path) => {
      if (path) {
        this.selectedPath = path
        this.resetPath()
        this.printPath(path)
      }
    })

    this.routeService.selectedStep.subscribe(step => {
      if (step) {
        this.selectedStep = step
        this.resetPath()
        this.printPath(this.selectedPath)
      }
    })

    this.routeService.origen.subscribe(origen => {
      this.origin = origen
      if (origen) {
        if (origen.place_id !== 'located') {
          this.setMarker('origin', origen.coords)
        } else {
          this.mapa.panTo(origen.coords)
          this.setBounds()
        }
        if (this.destiny) {
          this.drawRoutePreview()
        }
      } else {
        if (this.markers.origin) {
          this.markers.origin.setMap(null)
        }
        this.resetPath()
      }
    })

    this.routeService.destino.subscribe(destino => {
      this.destiny = destino
      if (destino) {
        this.setMarker('destiny', destino.coords)
        if (this.origin) {
          this.drawRoutePreview()
        }
      } else {
        if (this.markers.destiny) {
          this.markers.destiny.setMap(null)
        }
        this.resetPath()
      }
    })

    this.geolocationService.mapPositionLocated.subscribe(geolocatedPosition => {
      this.positionGeolocated = geolocatedPosition
      this.followUserLocation = geolocatedPosition
    })

    this.routeService.selectedOnMap.subscribe((selected) => {
      if (!selected && this.markers.click) {
        this.markers.click.setMap(null)
      }
    })
  }

  async drawRoutePreview() {
    const directions = new google.maps.DirectionsService

    const route = await directions.route({
      origin: this.origin.coords,
      destination: this.destiny.coords,
      travelMode: google.maps.TravelMode.DRIVING
    })

    this.printPath(route.routes[0].legs[0].steps)
  }

  drawRouteDetailsPolyline(polyline) {
    const decodePath = google.maps.geometry.encoding.decodePath(polyline)

    this.routeDetailsPolyline = new google.maps.Polyline({
      path: decodePath,
      strokeColor: indicatorsColors.selected.active,
      strokeOpacity: 1,
      strokeWeight: 4,
      map: this.mapa,
      zIndex: 99
    })
  }

  resetRouteDetailsPolyline() {
    this.routeDetailsPolyline.setMap(null)
  }

  loadMap(id: string) {
    const mapEle: HTMLElement = document.getElementById(id)
    this.mapa = new google.maps.Map(mapEle, {
      center: {
        lat: 41.3869741,
        lng: 2.1701107,
      },
      zoom: 18,
      disableDefaultUI: true,
      keyboardShortcuts: false
    })

    const trafficLayer = new google.maps.TrafficLayer();

    trafficLayer.setMap(this.mapa);

    this.mapa.addListener("drag", () => {
      if (this.positionGeolocated) {
        this.geolocationService.mapPositionLocated.next(false)
      }
    })

    this.mapa.addListener('zoom_changed', () => {
      const zoom = this.mapa.getZoom()
      const walkingRadiusVisible = this.walkingRadius.getMap()
      if (zoom < 13) {
        if (walkingRadiusVisible) {
          this.walkingRadius.setMap(null)
          this.walkingRadiusMarker.setMap(null)
        }
      } else if (!walkingRadiusVisible) {
        this.walkingRadius.setMap(this.mapa)
        this.walkingRadiusMarker.setMap(this.mapa)
      }
    })

    this.mapa.addListener("click", props => this.clickOnMap(props));

    this.userLocationDot = new google.maps.Marker({
      icon: this.locationDotMarker,
      map: this.mapa,
      position: this.userLocation?.coords ? new google.maps.LatLng(this.userLocation.coords.lat, this.userLocation.coords.lng) : undefined,
    })

    this.userLocationDot.addListener("click", props => this.clickOnMap(props))

    this.walkingRadiusMarker = new google.maps.Marker({
      icon: this.walkingRadiusMarkerIcon,
      map: this.mapa,
      label: {
        text: "5 min",
        className: 'walkingTimeLabel',
        color: indicatorsColors.selected.active,
        fontFamily: 'Inter',
        fontSize: '12px'
      }
    })

    this.markers.origin = new google.maps.Marker({
      label: 'A'
    })

    this.markers.click = new google.maps.Marker({
      label: '?'
    })

    this.markers.destiny = new google.maps.Marker({
      label: 'B'
    })

    this.markers.favorite = new google.maps.Marker({
      label: ''
    })

    this.userLocationDotRadius = new google.maps.Circle({
      strokeWeight: 0.5,
      strokeColor: indicatorsColors.selected.active,
      strokeOpacity: 1,
      fillColor: indicatorsColors.selected.active,
      fillOpacity: 0.2,
      map: this.mapa,
      zIndex: 0,
    })

    this.userLocationDotRadius.addListener("click", props => this.clickOnMap(props))

    this.walkingRadius = new google.maps.Polyline({
      strokeOpacity: 0,
      icons: [{
        icon: dashedLine,
        offset: '0',
        repeat: '20px'
      }],
      strokeWeight: 1,
      strokeColor: indicatorsColors.selected.active,
      map: this.mapa
    })

    this.geolocationService.location.pipe(first(location => !!location)).subscribe(
      (location: Ubicacion) => {
        if (location) {
          this.centerMap(location.coords)
          this.userLocation = location.coords
          this.updatePosition(location.coords)
        }
      }
    )
  }

  setUserMarkers(positions: []) {
    positions.map(position => {
      this.userMarkers.push(new google.maps.Marker({
        position: position,
        map: this.mapa
      }))
    })
  }

  resetUserMarkers() {
    this.userMarkers.map(marker => marker.setMap(null))
  }

  setMarker(type, position: { lat: number; lng: number }) {
    this.markers.click.setMap(null)
    this.markers[type].setPosition(position)
    this.markers[type].setMap(this.mapa)
    this.mapa.panTo(position)
    this.setBounds()
    if (this.positionGeolocated) {
      this.geolocationService.mapPositionLocated.next(false)
    }
  }

  setBounds(origin?, destination?) {
    if (origin?.coords && destination?.coords) {
      this.bounds = new google.maps.LatLngBounds();
      this.bounds.extend(origin.coords);
      this.bounds.extend(destination.coords);
      this.mapa.fitBounds(this.bounds, { bottom: this.paddingBottom, top: 64 });
    } else if (this.origin?.coords && this.destiny?.coords) {
      this.bounds = new google.maps.LatLngBounds()
      this.bounds.extend(this.origin.coords)
      this.bounds.extend(this.destiny.coords)
      this.mapa.fitBounds(this.bounds, {bottom: this.paddingBottom, top: 64})
    }
  }

  resetPath(polylineObject = this.polyline) {
    polylineObject.map((step) => {
      step.setMap(null)
    })
  }

  async printCurrentTripPath() {
    const directions = new google.maps.DirectionsService

    const route = await directions.route({
      origin: {
        lat: this.viajesService.currentTrip.getValue().origin.coords.lat,
        lng: this.viajesService.currentTrip.getValue().origin.coords.lng
      },
        destination: {
        lat: this.viajesService.currentTrip.getValue().destination.coords.lat,
        lng: this.viajesService.currentTrip.getValue().destination.coords.lng
      },
      travelMode: google.maps.TravelMode.DRIVING
    })

    this.printPath(route.routes[0].legs[0].steps)
  }

  async printCarpoolingPath(routeToFocus = 'origin') {
    this.resetPath(this.carpoolingPolylineOrigin)
    this.resetPath(this.carpoolingPolylineDestination)

    const directions = new google.maps.DirectionsService

    const origin = this.routine.routineConfig.value.selected_days.find((day) => day.route_type === "origin")
    const destination = this.routine.routineConfig.value.selected_days.find((day) => day.route_type === "destination")

    const bounds = new google.maps.LatLngBounds()

    if (origin) {
      const routeOrigin = await directions.route({
        origin: {
          lat: this.routine.origen.getValue().coords.lat,
          lng: this.routine.origen.getValue().coords.lng
        },
        destination: {
          lat: this.routine.destino.getValue().coords.lat,
          lng: this.routine.destino.getValue().coords.lng
        },
        travelMode: google.maps.TravelMode.DRIVING
      })

      this.carpoolingPolylineOrigin[0] = new google.maps.Polyline({
        path: google.maps.geometry.encoding.decodePath(routeOrigin.routes[0].overview_polyline),
        strokeColor: routeToFocus === 'origin' ? indicatorsColors.selected.active : indicatorsColors.selected.inactive,
        strokeOpacity: 1,
        strokeWeight: 4,
        map: this.mapa,
        zIndex: 99
      })

      const points = this.carpoolingPolylineOrigin[0].getPath().getArray()
      points.map(point => bounds.extend(point))
    }
    if (destination) {
      const routeDestination = await directions.route({
        origin: {
          lat: this.routine.destino.getValue().coords.lat,
          lng: this.routine.destino.getValue().coords.lng
        },
        destination: {
          lat: this.routine.origen.getValue().coords.lat,
          lng: this.routine.origen.getValue().coords.lng
        },
        travelMode: google.maps.TravelMode.DRIVING
      })

      this.carpoolingPolylineDestination[0] = new google.maps.Polyline({
        path: google.maps.geometry.encoding.decodePath(routeDestination.routes[0].overview_polyline),
        strokeColor: routeToFocus === 'destination' ? indicatorsColors.selected.active : indicatorsColors.selected.inactive,
        strokeOpacity: 1,
        strokeWeight: 4,
        map: this.mapa,
        zIndex: 99
      })

      const points = this.carpoolingPolylineDestination[0].getPath().getArray()
      points.map(point => bounds.extend(point))
    }

    this.setMarker('origin', this.routine.origen.getValue().coords)
    this.setMarker('destiny', this.routine.destino.getValue().coords)
    this.mapa.fitBounds(bounds, { bottom: 250, top: 80 })
  }

  printPath(route) {
    this.resetPath()
    this.drawPath(route)
  }

  drawPath(route, color?, polylineObject = this.polyline) {
    route.map((step, index) => {
      const strokeColor = color ? color : this.selectedStep === null || this.selectedStep === index
          ? indicatorsColors.selected.active
          : indicatorsColors.selected.inactive

      if (step.travel_mode === "WALKING") {
        polylineObject[index] = new google.maps.Polyline({
          path: google.maps.geometry.encoding.decodePath(step.polyline.points),
          strokeOpacity: 0,
          icons: [{
            icon: dottedLine,
            offset: '0',
            repeat: '20px'
          }],
          strokeWeight: 4,
          strokeColor,
          map: this.mapa,
          zIndex: 9999
        })
      } else {
        // TODO: Change origin and destination marker to taxi origin and taxi destination
        // if (index > 0 && route[index - 1].travel_mode === "WALKING") {
        //   this.setMarker('origin', step.start_location)
        // }
        // if (route[index + 1]?.travel_mode === "WALKING") {
        //   this.setMarker('destiny', step.end_location)
        // }
        polylineObject[index] = new google.maps.Polyline({
          path: google.maps.geometry.encoding.decodePath(step.polyline.points),
          strokeColor,
          strokeOpacity: 1,
          strokeWeight: 4,
          map: this.mapa,
          zIndex: 99
        })
      }
    })
  }

  centerToUserLocation() {
    this.centerMap({ lat: this.userLocation.lat, lng: this.userLocation.lng }, true)
    this.geolocationService.mapPositionLocated.next(true)
  }

  centerMap(coords: { lat: number; lng: number }, focus = false) {
    const coordsToCenter = new google.maps.LatLng(coords)
    if (this.mapa) {
      const mapCenter = this.mapa.getCenter()
      const distance = calculateDistance(coordsToCenter, mapCenter)

      this.mapa.panTo(coordsToCenter)

      if (focus && distance >= 0.01 && this.mapa.getZoom() !== 18) {
        this.mapa.setZoom(18)
      }

      if (distance < 0.01) {
        if (this.mapa.getZoom() === 16) {
          this.mapa.setZoom(18)
        }
      }
    }
  }

  updatePosition(location: { lat: number; lng: number; precision?: number }) {
    if (this.userLocationDot) {
      this.userLocationDot.setPosition({ lat: location.lat, lng: location.lng })

      this.userLocationDotRadius.setCenter({
        lat: location.lat,
        lng: location.lng,
      })
      this.userLocationDotRadius.setRadius(location.precision)

      this.walkingRadius.setPath(this.drawCircle({
        lat: location.lat,
        lng: location.lng,
      }, distanciaEn5min, 1))

      this.walkingRadiusMarker.setPosition({ lat: location.lat + (distanciaEn5min - 42) / 100000, lng: location.lng })
    }
  }

  resetMarkers() {
    this.markers.destiny.setMap(null)
    this.markers.origin.setMap(null)
  }

  drawCircle(point, radius, dir) {
    const d2r = Math.PI / 180 // degrees to radians
    const r2d = 180 / Math.PI // radians to degrees
    const earthRadius = 6378 * 1000 // radius of the earth in meters

    const points = 2000 // Cuantos más puntos tengamos, más preciso va a ser el círculo

    // find the raidus in lat/lon
    const rlat = (radius / earthRadius) * r2d
    const rlng = rlat / Math.cos(point.lat * d2r)

    let extp = new Array()
    let start, end
    if (dir === 1) {
      start = 0
      end = points + 1 // one extra here makes sure we connect the path
    } else {
      start = points + 1
      end = 0
    }
    for (let i = start; (dir === 1 ? i < end : i > end); i = i + dir) {
      const theta = Math.PI * (i / (points / 2))
      const ey = point.lng + (rlng * Math.cos(theta)) // center a + radius x * cos(theta)
      const ex = point.lat + (rlat * Math.sin(theta)) // center b + radius y * sin(theta)
      extp.push(new google.maps.LatLng(ex, ey))
    }
    return extp
  }

  async clickOnMap({latLng}) {
    this.geocoder
      .geocode({ location: latLng })
      .subscribe(res => {
        if (res.results.length > 0) {
          const locality = res.results[0].address_components.find(address => address.types.includes('locality')).long_name
          const data = {
            nombre: res.results[0].formatted_address,
            nombre_largo: res.results[0].formatted_address,
            locality: locality,
            coords: {
              lat: latLng.lat(),
              lng: latLng.lng(),
            },
            place_id: res.results[0].place_id
          }

          this.routeService.mapClick(data)
        }
      })

      this.modalsService.presentModal('modalSelectLocation')

      this.markers.click.setPosition(latLng)
      this.markers.click.setMap(this.mapa)
  }
}
