import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
})
export class InputComponent implements OnInit {
  @Input() public id: string
  @Input() public label: string
  @Input() public placeholder: string
  @Input() public type: string = "text"
  @Input() errors: string = ""
  @Input() inputModel: string
  @Input() disabled: boolean = false
  @Input() lowerText: string = ""
  @Input() data_cy: string = ""
  @Input() autocomplete: string = ""
  @Input() buttonText: string;
  
  focused: boolean = false
  
  showPassword: boolean = false
  
  @Output() inputModelChange = new EventEmitter<string>()
  @Output() buttonCallback = new EventEmitter<string>()

  constructor() { }

  ngOnInit() { }

  focus() {
    this.focused = true
  }

  blur() {
    this.focused = false
  }

  emitChange(e: Event) {
    this.inputModelChange.emit((e.target as HTMLInputElement).value)
  }

  buttonClick() {
    this.buttonCallback.emit()
  }
}
