import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { MyVehiclesService } from '@services/my-vehicles/my-vehicles.service';
import { RoutinesService } from '@services/routines/routines.service';

@Component({
  selector: 'app-day-edit',
  templateUrl: './day-edit.component.html',
  styleUrls: ['./day-edit.component.scss'],
})
export class DayEditComponent implements OnInit {
  @Input() editingDayInfo;

  route = this.routines.editingRoutineDirections.value;
  @ViewChild('selectVehicle') selectVehicle: any;

  exitTimeVisible: boolean = false;
  arrivalTimeVisible: boolean = false;

  private increaseInterval: any;
  private decreaseInterval: any;
  private readonly pressInterval: number = 100;
  priceChangeStep: number = 1;

  vehicles = this.myVehicles.vehicles.value;

  constructor(
    private analytics: AnalyticsService,
    private ngZone: NgZone,
    private myVehicles: MyVehiclesService,
    private routines: RoutinesService,
    private router: Router,
    private modalCtrl: ModalController
  ) {}

  async ngOnInit() {
    this.routines.getRoutineDirections();

    if (!this.editingDayInfo) {
      const selectedVehicle = this.vehicles.find((v) => v.active)?.fuel_type
        ?.slug;
      let selectedVehicleRecommendedPrice;
      if (selectedVehicle) {
        selectedVehicleRecommendedPrice =
          await this.routines.getRecommendedPrice(
            selectedVehicle,
            this.route.routes[0].legs[0].distance.value,
            this.route.routes[0].legs[0].duration.value
          );
      }
      this.editingDayInfo = {
        type: 'driver',
        exitTime: '08:00',
        arrivalTime: this.getArrivalTime('08:00'),
        capacity: 3,
        vehicle: this.vehicles.find((v) => v.active),
        price: selectedVehicleRecommendedPrice,
        recommended_price: selectedVehicleRecommendedPrice,
      };
    }
  }

  toggleSelectedType(type: 'driver' | 'rider' | 'both') {
    this.editingDayInfo.type = type

    const analyticsType =
      type === 'driver' ? 'conductor' : type === 'rider' ? 'pasajero' : 'ambos';
    this.analytics.logEvent(`ruta_${analyticsType}`, { type });
  }

  toggleTimeVisible(time) {
    if (time === 'exitTime') {
      this.exitTimeVisible = !this.exitTimeVisible;
      this.arrivalTimeVisible = false;
    } else {
      this.arrivalTimeVisible = !this.arrivalTimeVisible;
      this.exitTimeVisible = false;
    }
  }

  getArrivalTime(departureTime): string {
    const exitTimeInSeconds =
      departureTime.split(':')[0] * 3600 + departureTime.split(':')[1] * 60;

    const arrivalTimeInSeconds =
      exitTimeInSeconds + this.route.routes[0].legs[0].duration.value;

    const arrivalTimeHour = String(
      Math.floor(arrivalTimeInSeconds / 3600)
    ).padStart(2, '0');
    const arrivalTimeMinutes = String(
      Math.floor((arrivalTimeInSeconds % 3600) / 60)
    ).padStart(2, '0');

    return arrivalTimeHour + ':' + arrivalTimeMinutes;
  }

  onExitTimeChange(event) {
    if (this.exitTimeVisible) {
      this.editingDayInfo.arrivalTime = this.getArrivalTime(event.detail.value);
    }
  }

  onArrivalTimeChange(event) {
    if (this.arrivalTimeVisible) {
      const arrivalTimeInSeconds =
        event.detail.value.split(':')[0] * 3600 +
        event.detail.value.split(':')[1] * 60;

      const exitTimeInSeconds =
        arrivalTimeInSeconds - this.route.routes[0].legs[0].duration.value;

      const exitTimeHour = String(
        Math.floor(exitTimeInSeconds / 3600)
      ).padStart(2, '0');
      const exitTimeMinutes = String(
        Math.floor((exitTimeInSeconds % 3600) / 60)
      ).padStart(2, '0');

      this.editingDayInfo.exitTime = exitTimeHour + ':' + exitTimeMinutes;
    }
  }

  async onVehicleChange(event) {
    const vehicle = this.vehicles.find(
      (v) => v.matricula === event.detail.value
    );
    const selectedVehicleRecommendedPrice =
      await this.routines.getRecommendedPrice(
        vehicle.fuel_type.slug,
        this.route.routes[0].legs[0].distance.value,
        this.route.routes[0].legs[0].duration.value
      );
    this.editingDayInfo.recommended_price = selectedVehicleRecommendedPrice;
    this.editingDayInfo.price = selectedVehicleRecommendedPrice;
    this.priceChangeStep = 1;
    this.editingDayInfo.vehicle = vehicle;
  }

  newVehicle() {
    this.dismissModal();
    this.router.navigate(['edit-vehicle']);
  }

  dismissModal() {
    // this.modal.dismiss()
  }

  decreasePrice() {
    if (this.editingDayInfo.price % 10 === 0) {
      this.priceChangeStep = 10;
    }
    if (
      this.editingDayInfo.price >=
        this.editingDayInfo.recommended_price - 200 &&
      this.editingDayInfo.price > 0
    ) {
      this.editingDayInfo.price -= this.priceChangeStep;
    }
  }

  increasePrice() {
    if (this.editingDayInfo.price % 10 === 0) {
      this.priceChangeStep = 10;
    }
    if (
      this.editingDayInfo.price + this.priceChangeStep <=
      this.editingDayInfo.recommended_price + 200
    ) {
      this.editingDayInfo.price += this.priceChangeStep;
    }
  }

  preventContextMenuIncrease(event) {
    event.preventDefault();
  }

  preventContextMenuDecrease(event) {
    event.preventDefault();
  }

  onIncreasePricePressStart(): void {
    document.addEventListener('contextmenu', this.preventContextMenuIncrease);
    this.increaseInterval = setInterval(() => {
      this.ngZone.run(() => this.increasePrice());
    }, this.pressInterval);
  }

  onIncreasePricePressEnd(): void {
    document.removeEventListener(
      'contextmenu',
      this.preventContextMenuIncrease
    );
    clearInterval(this.increaseInterval);
  }

  onDecreasePricePressStart() {
    document.addEventListener('contextmenu', this.preventContextMenuDecrease);
    this.decreaseInterval = setInterval(() => {
      this.ngZone.run(() => this.decreasePrice());
    }, this.pressInterval);
  }

  onDecreasePricePressEnd() {
    document.removeEventListener(
      'contextmenu',
      this.preventContextMenuDecrease
    );
    clearInterval(this.decreaseInterval);
  }

  openSelect() {
    this.selectVehicle.open();
  }

  increaseCapacity() {
    if (this.editingDayInfo.capacity < 7) {
      this.editingDayInfo.capacity++;
    }
  }

  decreaseCapacity() {
    if (this.editingDayInfo.capacity > 0) {
      this.editingDayInfo.capacity--;
    }
  }

  save() {
    this.exitTimeVisible = false;
    this.arrivalTimeVisible = false;
    return this.modalCtrl.dismiss(null, 'confirm');
  }
}
