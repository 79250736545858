import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { AxiosService } from '@services/axios/axios.service';
import { FamiliasService } from '@services/familias/familias.service';
import { GoogleMapService } from '@services/google-map/google-map.service';
import { PaymentMethodsService } from '@services/payment-methods/payment-methods.service';
import { RouteService } from '@services/route/route.service';
import { UserService } from '@services/user/user.service';
import { VehiclesService } from '@services/vehicles/vehicles.service';
import { ViajesService } from '@services/viajes/viajes.service';

@Injectable({
  providedIn: 'root'
})
export class ResetServicesService {
  isAuth: boolean

  constructor(
    private auth: AuthenticationService,
    private axios: AxiosService,
    private paymentMethods: PaymentMethodsService,
    private route: RouteService,
    private user: UserService,
    private vehicles: VehiclesService,
    private viajes: ViajesService
  ) {
  }

  initializeService() {
    this.auth.isAuthenticated.subscribe((isAuthenticated) => {
      if (this.isAuth && !isAuthenticated) {
        this.isAuth = isAuthenticated
        this.resetServices()
      } else {
        this.isAuth = isAuthenticated
      }
    })
  }

  resetServices() {
    this.auth.reset()
    this.axios.reset()
    this.paymentMethods.reset()
    this.route.reset()
    this.user.reset()
    this.vehicles.reset()
    this.viajes.reset()

    Preferences.remove({ key: 'currentTripRequestLimit' })
  }
}
