import axios, { AxiosInstance } from 'axios';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { environment } from '@src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '@services/toast/toast-service';

@Injectable({
  providedIn: 'root',
})
export class AxiosService {
  private axios: AxiosInstance;
  public token: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  authService

  constructor(
    private toast: ToastService,
    @Optional() @SkipSelf() sharedService?: AxiosService
  ) {
    if (sharedService) {
    } else {
      Preferences.get({ key: 'token' }).then((token) => {
        if (token.value == null) {
          token.value = 'null';
        }

        this.token.next(token.value);

        this.axios = axios.create({
          baseURL: environment.apiUrl,
        });

        this.axios.interceptors.response.use(
          (response) => response,
          async (error) => {
            await this.errorHandling(error)
            return Promise.reject(error);
          }
        );

        this.axios.interceptors.request.use(config => {
          config.headers.Authorization = "Bearer " + this.token.getValue();
          return config;
        });
      });
    }
  }

  async getInstance() {
    await new Promise((resolve) => {
      const intervalID = setInterval(() => {
        if (this.axios) {
          clearInterval(intervalID);
          resolve('done!');
        }
      }, 300);
    });

    return this.axios;
  }

  setToken(token: string) {
    Preferences.set({ key: 'token', value: token ? token : 'null' });
    this.token.next(token);
  }

  async errorHandling(error) {
    console.warn(error)
    if (error.response.status === 422 || (error.config.url === "/social-login" && error.response.status === 404)) {
      return
    }
    if (error.response.status === 401 && error.config.url !== "/login" && this.token.getValue() && this.token.getValue() !== 'null') {
      this.authService.logout();
      this.toast.presentToast('Ha habido un error y se ha cerrado tu sesión. Por favor, prueba a volver a iniciar sesión.', 'error');
    }
    throw error
  }

  setAuthService(authService) {
    this.authService = authService
  }

  reset() {
    this.token.next(null)
  }
}
