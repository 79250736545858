import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth/auth.guard';
import { AutoLoginGuard } from './guards/auto-login/auto-login.guard';
import { NgModule } from '@angular/core';
import { PhoneValidationGuard } from './guards/phone-validation/phone-validation.guard';
import { RoutineDirectionsGuard } from './guards/routines-guards/routine-directions.guard';
import { RoutineConfigGuard } from './guards/routines-guards/routine-config.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canLoad: [AutoLoginGuard],
  },
  {
    path: 'signup/:phone/country-code/:countryCode',
    loadChildren: () =>
      import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'signup/:email',
    loadChildren: () =>
      import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'password-recovery',
    loadChildren: () =>
      import('./pages/password-recovery/password-recovery.module')
        .then((m) => m.PasswordRecoveryPageModule),
  },
  {
    path: 'otp',
    loadChildren: () =>
      import('./pages/otp/otp.module').then((m) => m.OtpPageModule),
  },
  {
    path: 'otp-email',
    loadChildren: () =>
      import('./pages/otp-email/otp-email.module').then((m) => m.OtpEmailPageModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'verify-sms/:phone/country-code/:countryCode',
    loadChildren: () => import('./pages/verify-sms/verify-sms.module').then(m => m.VerifySMSPageModule)
  },
  {
    path: 'verify-email/:email',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./pages/contacto/contacto.module').then( m => m.ContactoPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'docs',
    loadChildren: () => import('./pages/docs/docs.module').then( m => m.DocsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'add-payment-method',
    loadChildren: () => import('./pages/add-payment-method/add-payment-method.module').then( m => m.AddPaymentMethodPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'trip-history',
    loadChildren: () => import('./pages/trip-history/trip-history.module').then( m => m.TripHistoryPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'trip-info/:tripId',
    loadChildren: () => import('./pages/trip-info/trip-info.module').then( m => m.TripInfoPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'opinions',
    loadChildren: () => import('./pages/opinions/opinions.module').then( m => m.OpinionsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'trip-preferences',
    loadChildren: () => import('./pages/trip-preferences/trip-preferences.module').then( m => m.TripPreferencesPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'my-vehicles',
    loadChildren: () => import('./pages/my-vehicles/my-vehicles.module').then( m => m.MyVehiclesPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'edit-vehicle',
    loadChildren: () => import('./pages/edit-vehicle/edit-vehicle.module').then( m => m.EditVehiclePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'edit-vehicle/:id',
    loadChildren: () => import('./pages/edit-vehicle/edit-vehicle.module').then( m => m.EditVehiclePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'route-search',
    loadChildren: () => import('./pages/route-search/route-search.module').then( m => m.RouteSearchPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'route-config',
    loadChildren: () => import('./pages/route-config/route-config.module').then( m => m.RouteConfigPageModule),
    canLoad: [AuthGuard, RoutineDirectionsGuard],
  },
  {
    path: 'publish-route',
    loadChildren: () => import('./pages/publish-route/publish-route.module').then( m => m.PublishRoutePageModule),
    canLoad: [AuthGuard, RoutineConfigGuard],
  },
  {
    path: 'transactions',
    loadChildren: () => import('./pages/transactions/transactions.module').then( m => m.TransactionsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'payment-methods',
    loadChildren: () => import('./pages/payment-methods/payment-methods.module').then( m => m.PaymentMethodsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'walkthrough',
    loadChildren: () => import('./pages/walkthrough/walkthrough.module').then( m => m.WalkthroughPageModule)
  },
  {
    path: 'routine-details/:id/:day/:route_type',
    loadChildren: () => import('./pages/routine-details/routine-details.module').then( m => m.RoutineDetailsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'route-edit/:id/:day/:route_type',
    loadChildren: () => import('./pages/route-config/route-config.module').then( m => m.RouteConfigPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'language',
    loadChildren: () => import('./pages/language/language.module').then( m => m.LanguagePageModule)
  },
  {
    path: 'promotion-codes',
    loadChildren: () => import('./pages/promotion-codes/promotion-codes.module').then( m => m.PromotionCodesPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'route-details-map/:id',
    loadChildren: () => import('./pages/route-details-map/route-details-map.module').then( m => m.RouteDetailsMapPageModule)
  },
  {
    path: 'pooling-wip',
    loadChildren: () => import('./pages/pooling-wip/pooling-wip.module').then( m => m.PoolingWIPPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
