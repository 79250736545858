import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FlexibleUpdateInstallStatus } from '@capawesome/capacitor-app-update';
import { ModalsService } from '@services/modals/modals.service';
import { StoreUpdatesService } from '@services/storeUpdates/store-updates.service';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

@Component({
  selector: 'app-store-updates-modal',
  templateUrl: './store-updates-modal.component.html',
})
export class StoreUpdatesModalComponent implements OnInit {
  storeUpdatesModal: CupertinoPane
  storeUpdatesModalSettings: CupertinoSettings = {
    bottomClose: true,
    fastSwipeClose: false,
    buttonDestroy: false,
    breaks: {
      top: { enabled: true, height: 200 },
      middle: { enabled: false },
      bottom: { enabled: true, height: 0 }
    },
    initialBreak: 'top',
    parentElement: 'body'
  }

  wasPresented: boolean = false
  updateState
  flexibleUpdateStatuses = FlexibleUpdateInstallStatus

  constructor(
    private modals: ModalsService,
    private storeUpdates: StoreUpdatesService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.modals.storeUpdatesModal.subscribe((modal) => {
      switch (modal?.action) {
        case 'present':
          this.storeUpdatesModal.present(...modal?.params)
          break
        case 'moveToBreak':
          this.storeUpdatesModal.moveToBreak(modal?.params[0])
          break
        case 'hide':
          this.storeUpdatesModal.hide()
          break
        case 'destroy':
          this.storeUpdatesModal.destroy(...modal?.params)
          break
      }
    })
  }

  installUpdate() {
    this.storeUpdates.completeFlexibleUpdate()
  }

  openAppStore() {
    this.storeUpdates.openAppStore()
  }

  ngOnInit() {
    this.storeUpdatesModal = new CupertinoPane(
      '.storeUpdatesModal',
      this.storeUpdatesModalSettings
    )

    this.storeUpdates.updateState.subscribe(async updateState => {
      this.updateState = updateState

      if (updateState && (!this.wasPresented || this.storeUpdatesModal.isHidden())) {
        this.changeDetector.detectChanges()
        await this.storeUpdatesModal.present()
        this.wasPresented = true
      }
    })
  }
}
