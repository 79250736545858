import { Pipe, PipeTransform } from '@angular/core';
import Activo from '@app/models/Activo';
import { Orders } from '@app/models/Orders';

@Pipe({
  name: 'sortActivosBy'
})
export class SortActivosByPipe implements PipeTransform {
  transform(value: object[], sortBy?: Orders): any[] {
    return value.sort((a: Activo, b: Activo) => {
      const valueA = this.returnValue(a, sortBy)
      const valueB = this.returnValue(b, sortBy)
      
      if (valueA > valueB) return 1
      if (valueA < valueB) return -1
      return 0
    })
  }

  returnValue = (activo: Activo, sortBy?: Orders): number => {
    if (sortBy === 'price') {
      let value = activo.precio

      if (activo.operador.name === "car-pooling") {
        value -= 500
        if (!activo.tarifaable_id) {
          value -= 1000
        }
      } else if (activo.familia.name === "taxi" && activo.operador.name !== "taxi033") {
        value += 200
      }

      return value
    } else if (sortBy === 'time') {
      let value = activo.tiempo.total

      if (activo.operador.name === "car-pooling") {
        value -= 500
        if (!activo.tarifaable_id) {
          value -= 1000
        }
      }

      if (activo.familia.name === "taxi") {
        if (activo.operador.name === "taxi033") {
          value += 5
        } else {
          value += 15
        }
      }

      if (activo.distancia.desde_origen_hasta_activo === 0) {
        value += 999999
      }

      return value
    }
    return activo.precio
  }
}
