import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-circular-chart',
  templateUrl: './circular-chart.component.html',
  styleUrls: ['./circular-chart.component.scss']
})
export class CircularChartComponent implements OnInit {
  @Input() progress: number = 75;
  strokeDasharray: string = '';

  ngOnInit(): void {
    this.updateStrokeDasharray();
  }

  updateStrokeDasharray(): void {
    const circumference = 2 * Math.PI * 45;
    const progressValue = (this.progress / 100) * circumference;
    this.strokeDasharray = `${progressValue} ${circumference}`;
  }
}
