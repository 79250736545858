import { Injectable } from '@angular/core';
import { DeleteRoutineModalComponent } from '@components/delete-routine-modal/delete-routine-modal.component';
import { ModalController, Platform } from '@ionic/angular';
import { RoutinesService } from '@services/routines/routines.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeleteRoutineService {
  modal
  open = new BehaviorSubject<boolean>(false)
  routineId: string

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private routines: RoutinesService
  ) { }

  async openModal(routineId: string) {
    this.routineId = routineId
    this.modal = await this.modalCtrl.create({
      component: DeleteRoutineModalComponent,
      backdropDismiss: false,
      id: "deleteRoutineModal",
      cssClass: this.platform.is("android") && "android"
    });
    this.modal.present();
    this.open.next(true)

    await this.modal.onWillDismiss();
    this.onDismiss()
  }

  onDismiss() {
    this.open.next(false)
  }

  dismiss() {
    this.modal?.dismiss()
  }

  async deleteRoutine() {
    await this.routines.deleteRoutine(this.routineId, new Date())
    this.dismiss()
  }
}
