import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

type Type = 'error' | 'info'

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(public toastController: ToastController) { }

  async presentToast(message: string, type: Type, options?: ToastOptions) {
    const toast = await this.toastController.create({
      position: 'bottom',
      duration: 3000,
      message,
      cssClass: type,
      htmlAttributes: {'data-cy': 'toast'},
      ...options
    });
    toast.present();
  }
}
