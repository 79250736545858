import { Injectable } from '@angular/core';
import { CarpoolingResultsComponent } from '@components/carpooling-results/carpooling-results.component';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ComparadorService } from '../comparador/comparador.service';
import { MyWaiisService } from '../my-waiis/my-waiis.service';

@Injectable({
  providedIn: 'root'
})
export class CarpoolingResultsService {
  modal
  open = new BehaviorSubject<boolean>(false)

  constructor(
    private modalCtrl: ModalController,
    private comparador: ComparadorService,
    private myWaiis: MyWaiisService
  ) { }

  async openModal() {
    this.myWaiis.setCarpoolingVisible(true)
    this.modal = await this.modalCtrl.create({
      component: CarpoolingResultsComponent,
      breakpoints: [0, 0.45, 0.7, 1],
      initialBreakpoint: 0.45,
      backdropDismiss: false,
      handle: true,
      showBackdrop: false
    });
    this.modal.present();
    this.open.next(true)

    await this.modal.onWillDismiss();
    this.comparador.openModal()
    this.open.next(false)
    this.myWaiis.setCarpoolingVisible(false)
  }
}
