import { BehaviorSubject, Subject } from 'rxjs';
import { Geolocation, Position } from '@capacitor/geolocation';

import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import Ubicacion from '@app/models/Ubicacion';
import { TranslateService } from '@services/translate/translate.service';
import BackgroundGeolocation, {
  Location
} from "@transistorsoft/capacitor-background-geolocation";
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Preferences } from '@capacitor/preferences';
import { environment } from '@envs';

import { CapacitorHttp } from "@capacitor/core";
import { throttle } from '@app/utils'
import { LocationPermissions2Service } from '@services/modals/locationPermissions2/location-permissions2.service';
import { LogsService } from '@services/logs/logs.service';
import { Capacitor } from '@capacitor/core';
import { MapGeocoder } from '@angular/google-maps';

declare type Coords = {
  latitude: number;
  longitude: number;
  accuracy: number;
  speed?: number;
};

declare type Geofence = {
  identifier: string;
  latitude: number;
  longitude: number;
  radius?: number;
  notifyOnEntry?: boolean;
  notifyOnExit?: boolean;
  notifyOnDwell?: boolean;
  loiteringDelay?: number;
}

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  public location = new BehaviorSubject<Ubicacion | undefined>(null);

  permission = new Subject<boolean>()
  mapPositionLocated = new BehaviorSubject<boolean>(false)
  shouldCenterMap = true

  locatedOrigin = new BehaviorSubject<boolean>(true)

  isNativePlatform = Capacitor.isNativePlatform();

  userLocations = new BehaviorSubject<{
    lat: number;
    lng: number;
    captured_at: string;
    source: string;
  }[]>([])

  constructor(
    private platform: Platform,
    private translateService: TranslateService,
    private authentication: AuthenticationService,
    private locationPermissionsModal2: LocationPermissions2Service,
    private logs: LogsService,
    private geocoder: MapGeocoder
  ) {
    this.userLocations.subscribe(userLocations => {
      if (userLocations.length > 0) {
        throttle(() => this.sendUserLocations(), 60000)
      }
    })

    this.permission.subscribe((permission) => {
      if (this.platform.is('capacitor')) {
        this.watchBackgroundPosition()
      }
    })
  }

  watchPosition() {
    Geolocation.watchPosition({
      enableHighAccuracy: true,
    }, (position) => {
      if (position) {
        throttle(() => this.updatePosition(position.coords), 5000)
      }
    })
  }

  checkPermissions() {
    Geolocation.checkPermissions().then(result => {
      if (result.location === "granted") {
        this.permission.next(true)
      } else {
        if (!this.platform.is('mobileweb')) {
          this.locationPermissionsModal2.openModal()
        }
      }
    })
  }

  initializeService() {
    Geolocation.checkPermissions().then(result => {
      if (result.location === "granted") {
        this.permission.next(true)
      }
    })

    this.permission.subscribe(async (permission) => {
      if (permission) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        }).then((position: Position) => {
          this.updatePosition(position.coords)
          this.mapPositionLocated.next(true)
        })
        this.watchPosition()
      }
    })
  }

  locality: string;
  address: string = "";

  watchBackgroundPosition() {
    if (!this.isNativePlatform) {
      return
    }
    BackgroundGeolocation.onLocation((location: Location) => {
      this.logs.log('background_geolocation_location', {
        location: {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
          accuracy: location.coords.accuracy,
          speed: location.coords.speed,
          timestamp: location.timestamp
        }
      })
      const existingLocation = this.userLocations.getValue().find(item => {
        return location.sample || (item.lat === location.coords.latitude && item.lng === location.coords.longitude && item.captured_at === location.timestamp)
      })
      if (!existingLocation) {
        this.userLocations.next([
          ...this.userLocations.getValue(),
          {
            lat: location.coords.latitude,
            lng: location.coords.longitude,
            captured_at: location.timestamp,
            source: "background"
          }
        ])
      }
    })

    BackgroundGeolocation.onMotionChange((motion) => {
      this.logs.log('background_geolocation_motion_change', {
        motion
      })
    })

    BackgroundGeolocation.onProviderChange((provider) => {
      this.logs.log('background_geolocation_provider_change', {
        provider
      })
    })

    BackgroundGeolocation.onPowerSaveChange((powerSave) => {
      this.logs.log('background_geolocation_power_save_change', {
        powerSave
      })
    })

    BackgroundGeolocation.onGeofence(geofence => {
      const location = geofence?.location
      const existingLocation = this.userLocations.getValue().find(item => {
        return location?.sample || (item.lat === location?.coords?.latitude && item.lng === location?.coords?.longitude && item.captured_at === location?.timestamp)
      })

      if (!existingLocation) {
        this.userLocations.next([
          ...this.userLocations.getValue(),
          {
            lat: location.coords.latitude,
            lng: location.coords.longitude,
            captured_at: location.timestamp,
            source: `geofence/${geofence.action}`
          }
        ])

        this.sendUserLocations()
      }

      this.logs.log('background_geolocation_geofence', {
        geofence
      })
    });

    BackgroundGeolocation.onNotificationAction((buttonId: string) => {
      console.warn('debug [onNotificationAction] buttonId:', buttonId);

      // Handle the notification click here
      // For example, navigate to a specific page in your app
    });

    BackgroundGeolocation.ready({
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      distanceFilter: 50,
      stopTimeout: 5,
      enableHeadless: true,
      stopOnTerminate: false,
      startOnBoot: true,
      showsBackgroundLocationIndicator: false,
      notification: {
        sticky: true,
        title: "Waiis",
        text: "Seguimiento de la ubicación activado",
        priority: BackgroundGeolocation.NOTIFICATION_PRIORITY_MIN,
        layout: "location_tracking_layout",
        strings: {
          notificationButtonBar: "Desactivar notificación"
        },
        actions: [
          'notificationButtonBar',
        ],
      }
    }).then((state) => {
      BackgroundGeolocation.start()
      this.logs.log('background_geolocation_started')
    });
  }

  async addGeofence(geofence: Geofence) {
    if (!this.isNativePlatform) {
      return
    }
    const existingGeofence = await BackgroundGeolocation.getGeofence(geofence.identifier)

    if (!existingGeofence) {
      BackgroundGeolocation.addGeofence({
        ...geofence,
        radius: geofence.radius ?? 200,
        notifyOnEntry: geofence.notifyOnEntry ?? true,
        notifyOnExit: geofence.notifyOnExit ?? true,
        notifyOnDwell: false,
        loiteringDelay: geofence.loiteringDelay ?? 600000,
      })
    }
  }

  async updatePosition(position: Coords) {
    const latLng = {
      lat: position.latitude,
      lng: position.longitude
    }

    this.geocoder
      .geocode({ location: latLng })
      .subscribe(res => {
        if (res.results.length > 0) {
          this.address = res.results[0].formatted_address
          this.locality = res.results[0].address_components.find(address => address.types.includes('locality')).long_name
          this.location.next({
            nombre: this.translateService.instant("search.your_location"),
            nombre_largo: this.address,
            locality: this.locality,
            coords: {
              lat: position.latitude,
              lng: position.longitude,
              precision: position.accuracy,
              speed: position.speed
            },
            place_id: 'located'
          });
        }
      })
  }

  sendUserLocations() {
    try {
      if (this.authentication.isAuthenticated.getValue()) {
        Preferences.get({ key: 'token' }).then(async (token) => {
          const options = {
            url: environment.apiUrl + '/user-locations/bulk',
            headers: {
              Authorization: "Bearer " + token.value,
              'Content-Type': 'application/json'
            },
            data: {
              locations: this.userLocations.getValue()
            },
          };

          this.logs.log('send_user_locations', {
            locations: this.userLocations.getValue()
          })

          const response = await CapacitorHttp.post(options);
          this.userLocations.next([])
        })
      }
    } catch (error) {
      console.warn("error", error)
    }
  }
}
