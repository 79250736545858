import { Component, OnInit } from '@angular/core';
import { environment } from '@envs';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '@services/modals/modals.service';
import { PaymentMethodsService } from '@services/payment-methods/payment-methods.service';
import { ToastService } from '@services/toast/toast-service';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

declare const Stripe

@Component({
  selector: 'app-add-payment-method-modal',
  templateUrl: './add-payment-method-modal.component.html',
  styleUrls: ['./add-payment-method-modal.component.scss'],
})
export class AddPaymentMethodModalComponent implements OnInit {
  stripe = Stripe(environment.stripeKey)
  elements = this.stripe.elements()
  processing: boolean = false
  modalAddPaymentMethod: CupertinoPane;
  modalAddPaymentMethodSettings: CupertinoSettings = {
    bottomClose: true,
    fastSwipeClose: false,
    buttonDestroy: true,
    parentElement: "body",
    breaks: {
      top: {
        enabled: true,
        height: 300,
      },
      middle: {
        enabled: false
      },
      bottom: {
        enabled: true,
        height: 0
      } 
    },
    initialBreak: 'top'
  }

  constructor(
    private modalsService: ModalsService,
    private paymentMethodService: PaymentMethodsService,

    private toast: ToastService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.setStripeElements()

    this.modalAddPaymentMethod = new CupertinoPane(
      '.modalAddPaymentMethod',
      this.modalAddPaymentMethodSettings
    )

    this.modalsService.modalAddPaymentMethod.subscribe((modal) => {
      switch (modal?.action) {
        case 'present':
          this.modalAddPaymentMethod.present(...modal?.params)
          break
        case 'moveToBreak':
          this.modalAddPaymentMethod.moveToBreak(modal?.params[0])
          break
        case 'hide':
          this.modalAddPaymentMethod.hide()
          break
        case 'destroy':
          this.modalAddPaymentMethod.destroy(...modal?.params)
          break
      }
    })
  }

  setStripeElements() {
    const style = {
      base: {
        iconColor: '#1dffcf',
        color: '#fff',
        fontWeight: '400',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#ffffff80',
          fontWeight: '400',
        },
      },
      invalid: {
        iconColor: '#ef4444',
        color: '#ef4444',
      },
    }
    const classes = {
      complete: 'border-primary border-2',
      focus: 'border-primary border-2',
      invalid: 'bg-white border-2 border-red-500'
    }

    const cardNumber = this.elements.create('cardNumber', { style, classes });
    const cardExpiry = this.elements.create('cardExpiry', { style, classes });
    const cardCvc = this.elements.create('cardCvc', { style, classes });

    cardNumber.mount('#card-number-modal');
    cardExpiry.mount('#card-expiry-modal');
    cardCvc.mount('#card-cvc-modal');

    const cardButton = document.getElementById('card-button-modal')

    cardButton.addEventListener('click', async (e) => {
      this.processing = true
      const { token, error } = await this.stripe.createToken(cardNumber)

      if (error) {
        this.processing = false
        console.error(error.message)
        this.toast.presentToast(this.translate.instant(error.message), 'error', {
          position:"top"
        });
      } else {
        console.info("The card has been verified successfully...")
      }

      this.paymentMethodService.addCard(token.id)
        .then(() => {
          this.modalsService.hideModal('modalAddPaymentMethod')
          this.processing = false
        })
        .catch((err) => {
          this.processing = false
          console.error(err)
          this.toast.presentToast(this.translate.instant(err), 'error', {
            position:"top"
          });
        })
    })
  }
}
