import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { capitalizeFirstLetter, formatCo2, formatPrice, formatTime } from '@app/utils';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { ComparadorService } from '@services/modals/comparador/comparador.service';
import { ResumenCarpoolingService } from '@services/modals/resumen-carpooling/resumen-carpooling.service';
import { ResumenRentService } from '@services/modals/resumen-rent/resumen-rent.service';
import { MyVehiclesService } from '@services/my-vehicles/my-vehicles.service';
import { RouteService } from '@services/route/route.service';
import { RoutinesService } from '@services/routines/routines.service';
import { UserService } from '@services/user/user.service';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activo',
  templateUrl: './activo.component.html',
})
export class ActivoComponent implements OnInit {
  @Input() carpoolingList: boolean = false;
  @Input() activo
  @Input() selectedDepartureTime

  @Input() selected: boolean;
  @Output() onSelect = new EventEmitter()

  price
  co2
  time

  steps: any[] = []
  arrival_time
  departure_time

  vehicle: string = ""
  vehicles: string[] = []

  transbordos = 0

  departureTime: string
  arrivalTime: string

  userImage: string
  userSubscription: Subscription

  myVehicles: any[] = []
  myVehiclesSubscription: Subscription

  title: string
  description_closed: string
  description_opened: string

  constructor(
    private route: RouteService,
    private analytics: AnalyticsService,
    private resumenRent: ResumenRentService,
    private comparador: ComparadorService,
    private routinesService: RoutinesService,
    private router: Router,
    private user: UserService,
    private myVehiclesService: MyVehiclesService,
    private resumentCarpooling: ResumenCarpoolingService
  ) { }

  ngOnInit() {
    this.myVehiclesSubscription = this.myVehiclesService.vehicles.subscribe(vehicles => this.myVehicles = vehicles)

    this.price = this.formatPrice()
    this.co2 = this.formatCo2()
    this.time = this.formatTime()

    this.title = capitalizeFirstLetter(this.activo.operador.name)

    let offset = 0

    if (dayjs(this.selectedDepartureTime).isSame(dayjs(), "minute")) {
      offset = 120
    }

    this.departureTime = dayjs(this.selectedDepartureTime).add(offset, 'second').format('HH:mm')
    this.arrivalTime = dayjs(this.selectedDepartureTime).add(offset + this.activo.tiempo.total, 'second').format('HH:mm')

    if (this.activo.familia.name === "transporte_publico") {
      this.steps = []

      this.title = "Transporte público"
      this.description_closed = "Viaje en transporte público"
      this.description_opened = ""

      const dir = [...this.activo.directions.origin_to_destination.routes[0].legs]

      dir.map(leg => {
        this.arrival_time = leg.arrival_time.text
        this.departure_time = leg.departure_time.text

        leg.steps.forEach((step, index) => {
          const prevStep = leg.steps[index - 1]
          const nextStep = leg.steps[index + 1]

          switch (step.travel_mode) {
            case google.maps.TravelMode.WALKING:
              if (
                prevStep?.travel_mode === google.maps.TravelMode.TRANSIT &&
                nextStep?.travel_mode === google.maps.TravelMode.TRANSIT &&
                step.distance.value === 0
              ) {
                this.steps.push({
                  text: `transbordo`
                })
              } else {
                this.steps.push({
                  travelMode: step.travel_mode,
                  duration: step.duration.text,
                  distance: step.distance.text
                })
              }
              break
            case google.maps.TravelMode.TRANSIT:
              let entrance
              let exit

              if (prevStep?.travel_mode === google.maps.TravelMode.WALKING) {
                const extractedInfo = prevStep.steps[prevStep.steps.length -1]?.html_instructions
                entrance = extractedInfo ? this.extractLocationFromHTML(extractedInfo) : null
              }

              if (nextStep?.travel_mode === google.maps.TravelMode.WALKING) {
                const extractedInfo = nextStep.steps[0]?.html_instructions
                exit = extractedInfo ? this.extractLocationFromHTML(extractedInfo) : null
              }

              this.steps.push({
                travelMode: step.travel_mode,
                duration: step.duration.text,
                departure_time: step.transit_details.departure_time.text,
                arrival_time: step.transit_details.arrival_time.text,
                line: {
                  name: step.transit_details.line.short_name,
                  color: step.transit_details.line.color,
                  text_color: step.transit_details.line.text_color,
                  icon: step.transit_details.line.vehicle.local_icon,
                  type: step.transit_details.line.vehicle.type
                },
                headsign: step.transit_details.headsign,
                stops: step.transit_details.num_stops,
                entrance,
                exit
              })
              break
          }
        })
      })
    }

    if (this.activo.operador.name === "car-pooling") {
      this.title = "WAIISPool"
      this.description_closed = this.activo.tarifaable_id ? "Viaja con otro usuario en su coche" : "Viaja en tu coche"
      this.description_opened = this.activo.tarifaable_id ? "Ocupa un asiento y compartid gastos" : "Cobra por tus asientos"
      this.vehicle = "carpooling.png"
    }
    else if (this.activo.familia.name === "taxi" || this.activo.familia.name === "coche") {
      if (this.activo.operador.name === "taxi033" || this.activo.operador.name === "waiis-taxis") {
        this.title = "Taxi"
        this.description_closed = "Viaja en taxi con precio cerrado"
        this.description_opened = "La opción más rápida utilizando los carriles bus"
        if (this.activo.origin.locality === "Barcelona") {
          this.vehicle = "taxi_Barcelona.png";
        } else if (this.activo.origin.locality === "Madrid") {
          this.vehicle = "taxi_Madrid.png";
        }
      } else {
        this.vehicle = "vtc.png"
      }
    } else if (this.activo.familia.name === "moto") {
      this.description_closed = "Alquila una moto"
      this.description_opened = ""
      this.vehicle = "moto_sharing.png"
    } else if (this.activo.familia.name === "transporte_publico") {
      this.steps.map(step => {
        if (step.travelMode !== "WALKING") {
          this.transbordos += 1
          if (step.line?.type === "SUBWAY" && !this.vehicles.includes("tren.png")) {
            this.vehicles.push("tren.png")
          } else if (step.line?.type === "BUS" && !this.vehicles.includes("bus.png")) {
            this.vehicles.push("bus.png")
          } else if(step.line?.type === "TRAM" && !this.vehicles.includes("tranvia.png")) {
            this.vehicles.push("tranvia.png")
          } else if (!this.vehicles.includes("tren.png")) {
            this.vehicles.push("tren.png")
          }
        }
      })
    } else if (this.activo.familia.name === "bici") {
      this.description_closed = "Alquila un bicicleta"
      this.description_opened = ""
      this.vehicle = "bici.png"
    } else if (this.activo.familia.name === "patinete") {
      this.description_closed = "Alquila un patinete"
      this.description_opened = ""
      this.vehicle = "patinete.png"
    }

    this.userSubscription = this.user.user.subscribe(user => {
      this.userImage = user?.selfie_url ? user.selfie_url : 'assets/img/default_user_image.png'
    })
  }

  extractLocationFromHTML(html: string) {
    try {
      const el = document.createElement('div')
      el.innerHTML = html

      const location = el.getElementsByClassName('location')[0].innerHTML
      return location
    } catch (_) {
      return null
    }
  }

  formatPrice = () => {
    if (this.activo.estimated && this.activo.familia.name === 'taxi') {
      return formatPrice(this.activo.precio, 1);
    }

    return formatPrice(this.activo.precio);
  }

  formatTime = () => {
    let value = this.activo.tiempo.desde_activo_hasta_destino;

    if (this.activo.familia.name === "transporte_publico" || this.activo.tiempo.desde_activo_hasta_destino === 0) {
      value = this.activo.tiempo.total;
    }

    return formatTime(value);
  }

  formatCo2 = () => {
    return formatCo2(this.activo.co2);
  }

  get walkingDistance () {
    return this.activo.distancia.desde_origen_hasta_activo > 0 ? Math.ceil(this.activo.distancia.desde_origen_hasta_activo / 10) * 10 : 1
  }

  async click() {
    if (this.selected) {
      if (this.carpoolingList) {
        return
      }
      this.analytics.logEvent(
        `parrilla_${this.activo.familia.name}_${this.activo.operador.name}`,
        {
          origen: this.activo.origin.long_address,
          destino: this.activo.destination.long_address
        }
      )

      if (this.activo.operador.name === "car-pooling") {
        // await this.comparador.dismiss()
        if (this.activo.tarifaable_id) {
          // this.resumentCarpooling.openModal()
          // this.router.navigate(['/pooling-wip'])
          this.comparador.openPoolingWipModal()
        } else {
          await this.comparador.dismiss()
          if (this.myVehicles.length > 0) {
            this.routinesService.setUbicacion('origen', this.route.origen.getValue())
            this.routinesService.setUbicacion('destino', this.route.destino.getValue())
            this.router.navigate(['/route-config'])
          } else {
            this.router.navigate(['/edit-vehicle'])
          }
        }
      } else {
        this.route.selectActivo(this.activo);
      }

      // this.vehiclesService.selectActivo(this.activo.data)
      // this.comparador.dismiss()
      if (this.activo.familia.name === "moto" && this.activo.operador.name === "cooltra") {
        this.resumenRent.openModal()
      }
    } else {
      this.onSelect.emit(this.activo.id)
    }
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.myVehiclesSubscription.unsubscribe();
  }
}
