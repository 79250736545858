import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { GoogleMapService } from '@services/google-map/google-map.service';
import { ModalsService } from '@services/modals/modals.service';
import { RouteService } from '@services/route/route.service';
import { UserService } from '@services/user/user.service';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

@Component({
  selector: 'app-select-location-modal',
  templateUrl: './select-location-modal.component.html',
})
export class SelectLocationModalComponent implements OnInit {
  modalSelectLocation: CupertinoPane;
  modalSelectLocationSettings: CupertinoSettings = {
    bottomClose: true,
    fastSwipeClose: false,
    handleKeyboard: false,
    buttonDestroy: false,
    parentElement: 'body',
    initialBreak: 'top',
    breaks: {
      top: { enabled: true, height: 300 },
      middle: { enabled: false },
      bottom: { enabled: true, height: 0 }
    },
    events: {
      onDidDismiss: () => {
        this.routeService.mapClick(null)
      }
    }
  };

  location;
  isAuthenticated;

  constructor(
    private modalsService: ModalsService,
    private routeService: RouteService,
    private userService: UserService,
    private auth: AuthenticationService,
    private googleMaps: GoogleMapService
  ) { }

  ngOnInit() {
    this.modalSelectLocation = new CupertinoPane(
      '#modalSelectLocation',
      this.modalSelectLocationSettings
    )

    this.modalsService.modalSelectLocation.subscribe((modal) => {
      switch (modal?.action) {
        case 'present':
          this.modalSelectLocation.present(...modal?.params)
          break
        case 'moveToBreak':
          this.modalSelectLocation.moveToBreak(modal?.params[0])
          break
        case 'hide':
          this.modalSelectLocation.hide()
          break
        case 'destroy':
          this.modalSelectLocation.destroy(...modal?.params)
          break
      }
    })

    this.routeService.selectedOnMap.subscribe(location => {
      this.location = location
    })

    this.auth.isAuthenticated.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated

      if (!isAuthenticated) {
        this.modalSelectLocation.setBreakpoints({
          top: { enabled: true, height: 200 },
          middle: { enabled: false },
          bottom: { enabled: true, height: 0 }
        });
      } else {
        this.modalSelectLocation.setBreakpoints({
          top: { enabled: true, height: 300 },
          middle: { enabled: false },
          bottom: { enabled: true, height: 0 }
        });
      }
    })
  }

  selectAs(type: 'origen' | 'destino') {
    this.modalSelectLocation.hide()
    this.routeService.setUbicacion(type, this.location, true)
  }

  setAs(type: 'home' | 'work') {
    this.modalSelectLocation.hide()

    this.googleMaps.markers.click.setMap(null)

    const formatted_address = {
      street_address: this.location.nombre_largo,
      lat: this.location.coords.lat,
      lng: this.location.coords.lng,
      locality: this.location.locality
    }

    if (type === 'home') {
      this.userService.setHomeAddress(formatted_address)
    } else {
      this.userService.setWorkAddress(formatted_address)
    }
  }
}
