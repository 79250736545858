import { Component, OnInit } from '@angular/core'
import { tripStatuses } from '@app/constants';
import { ModalsService } from '@services/modals/modals.service';
import { ViajesService } from '@services/viajes/viajes.service';

declare type Status = 'list' | 'add' | 'processing' | 'success' | 'trip'

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
})
export class PaymentComponent implements OnInit {
  status: Status = 'list'

  constructor(
    private modalsService: ModalsService,
    private viajesService: ViajesService
  ) {
    this.viajesService.currentTrip.subscribe(trip => {
      if (trip?.state === tripStatuses.searching_driver) {
        this.status = 'list'
        this.modalsService.hideModal('modalPayment')
      }
    })
  }

  async ngOnInit() { }

  updateStatus(status: Status) {
    this.status = status
  }
}
