import { Injectable } from '@angular/core';
import { LocationPermissionsModal2Component } from '@components/location-permissions-modal2/location-permissions-modal2.component';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationPermissions2Service {
  modal
  open = new BehaviorSubject<boolean>(false)

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
  ) { }

  async openModal() {
    this.modal = await this.modalCtrl.create({
      component: LocationPermissionsModal2Component,
      backdropDismiss: false,
      id: "locationPermissionsModal2",
      cssClass: this.platform.is("android") && "android"
    });
    this.modal.present();
    this.open.next(true)

    await this.modal.onWillDismiss();
    this.onDismiss()
  }

  onDismiss() {
    this.open.next(false)
  }

  dismiss() {
    this.modal?.dismiss()
  }
}
