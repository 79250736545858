import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutinesService } from '@services/routines/routines.service';
import { displayDate, formatPrice } from '@app/utils';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@services/translate/translate.service';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';
import { weekdays } from '@app/constants';
import { Subscription } from 'rxjs';
import dayjs, { Dayjs } from 'dayjs';

const aWeekFromNow = dayjs().add(6, 'day')

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements OnInit {
  routeToDelete: string

  routineToDelete: string
  routineDayToDelete: string

  modalDeleteRoute: CupertinoPane
  modalDeleteRouteSettings: CupertinoSettings = {
    bottomClose: false,
    fitHeight: true,
    fastSwipeClose: false,
    handleKeyboard: false,
    parentElement: 'body',
    buttonDestroy: false,
    showDraggable: false,
    draggableOver: false,
    dragBy: [],
    backdrop: true,
  }

  modalDeleteRoutine: CupertinoPane
  modalDeleteRoutineSettings: CupertinoSettings = {
    bottomClose: false,
    fitHeight: true,
    fastSwipeClose: false,
    handleKeyboard: false,
    parentElement: 'body',
    buttonDestroy: false,
    showDraggable: false,
    draggableOver: false,
    dragBy: [],
    backdrop: true,
  }

  routes: any[] = []
  routines: any[] = []

  routinesSubscription: Subscription
  routesSubscription: Subscription

  next7Days = Array.from({ length: 7 }, (_, i) => dayjs().add(i, 'day'))

  constructor(
    private router: Router,
    private routinesService: RoutinesService,
    private actionSheetCtrl: ActionSheetController,
    private translate: TranslateService,
  ) {
    this.routinesSubscription = this.routinesService.routines.subscribe(routines => {
      this.routines = routines
    })
    this.routesSubscription = this.routinesService.routes.subscribe(routes => {
      this.routes = routes.filter(route => dayjs(route.start_date+route.departure_time).isAfter(dayjs())).filter(route => dayjs(route.start_date).isSameOrBefore(aWeekFromNow)).sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)))
    })
  }

  addRoute() {
    this.router.navigate(['route-search'])
  }

  getRoutesByDay(day: Dayjs) {
    return this.routes.filter(route => dayjs(route.start_date+route.departure_time).isSame(day, 'day'))
  }

  ngOnInit() {
    this.modalDeleteRoute = new CupertinoPane(
      '.modalDeleteRoute',
      this.modalDeleteRouteSettings
    )

    this.modalDeleteRoutine = new CupertinoPane(
      '.modalDeleteRoutine',
      this.modalDeleteRoutineSettings
    )
  }

  getWeekDay(date: Dayjs) {
    return displayDate(date, { onlyDayShort: true })
  }

  getRoutineName(route: any) {
    const routine = this.routines.find(routine => routine.id === route.pooling_routine_id)
    return routine?.name
  }

  routeDetails(route) {
    this.router.navigate(['routine-details', route.pooling_routine_id, weekdays.indexOf(route.pooling_routine_day.day), route.pooling_routine_day.route_type])
  }

  async presentActionSheet(event, route) {
    event.stopPropagation();

    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: this.translate.instant('my_waiis.agenda.details'),
          handler: () => {
            this.routeDetails(route)
          },
        },
        {
          text: this.translate.instant('common.delete'),
          role: 'destructive',
          handler: () => {
            this.routeToDelete = route.id
            this.modalDeleteRoute.present()
          },
        },
        {
          text: this.translate.instant('common.cancel'),
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }

  displayDepartureTime(route) {
    return displayDate(`${route.start_date}T${route.departure_time}`, { onlyHour: true })
  }

  displayPrice(route) {
    return formatPrice(route.price)
  }

  confirmDeleteRoute() {
    this.routinesService.deleteRoute(this.routeToDelete)
    this.modalDeleteRoute.hide()
  }

  confirmDeleteRoutine() {
    this.routinesService.deleteRoutine(this.routineToDelete, this.routineDayToDelete)
    this.modalDeleteRoutine.hide()
  }

  ngOnDestroy() {
    this.routinesSubscription.unsubscribe()
    this.routesSubscription.unsubscribe()
  }
}
