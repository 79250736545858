import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import * as SentryAngular from "@sentry/angular-ivy";
import * as Sentry from "@sentry/capacitor";
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

if (environment.production) {
  enableProdMode();
  CapacitorUpdater.notifyAppReady();
}

if (Capacitor.getPlatform() !== 'web') {
  App.getInfo().then(info => {
    Sentry.init({
      dsn: "https://399f8bebab26721da703ccf2e9711d81@o4506319721922560.ingest.sentry.io/4506319742173184",
      release: `waiis-users@${info.version} (${info.build})`,
      dist: "1",
      tracesSampleRate: 1.0,
      environment: environment.production ? "production" : "development",
      integrations: [
        SentryAngular.browserTracingIntegration(),
        SentryAngular.replayIntegration(),
      ],
    },
    SentryAngular.init);
  })
} else {
  console.log('App.getInfo() is not supported on the web platform.');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
