import { AxiosService } from '@services/axios/axios.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { Router } from '@angular/router';
import { ToastService } from '@services/toast/toast-service';
import { Preferences } from '@capacitor/preferences';
import { PusherService } from '@services/pusher/pusher.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  isPhoneValidated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  token: string;
  socialData

  signedUrl: string

  options: SignInWithAppleOptions = {
    clientId: 'eco.waiis.app',
    redirectURI: 'https://www.waiis.eco/login',
    scopes: 'email name',
  };

  verificationCode = new BehaviorSubject<number>(null)

  constructor(
    private axios: AxiosService,
    private analytics: AnalyticsService,
    private router: Router,
    private toast: ToastService,
    private pusher: PusherService,
  ) {
    this.axios.setAuthService(this)

    this.axios.token.subscribe((token) => {
      if (token == null) {
        Preferences.remove({ key: 'user' })
        return;
      }
      this.isAuthenticated.next(token != 'null');

      this.pusher.initialize()

      this.pusher.signin()

      this.pusher.pusher.bind_global((eventName, data) => {
      })
    });
  }

  saveToken(token: string) {
    this.axios.setToken(token);
    this.isAuthenticated.next(true);
  }

  async login(credentials) {
    return (await this.axios.getInstance())
      .post('/login', credentials)
      .then((response) => {
        this.analytics.logEvent('log_in', {})
        this.saveToken(response.data.token)
      })
  }

  logout() {
    this.axios.setToken(null);
    this.isAuthenticated.next(false);

    Preferences.clear();
    localStorage.clear();
  }

  async signUp(credentials) {
    return (await this.axios.getInstance())
      .post(this.signedUrl ?? '/register', {
        ...credentials,
        provider_name: this.socialData?.provider_name ?? undefined,
        access_token: this.socialData?.access_token ?? undefined
      })
      .then(async (response) => {
        this.analytics.logEvent('sign_up', {})
        this.saveToken(response.data.token);
      });
  }

  initializeGoogleAuth() {
    GoogleAuth.initialize({
      clientId: "679293452944-pfp62p6l6dtmusobifaaoiaqp5uguif9.apps.googleusercontent.com",
      scopes: ["profile", "email"]
    })
  }

  async googleLogin() {
    return GoogleAuth.signIn().then(response => {
      this.socialLogin('google', response.authentication.accessToken)

      this.socialData = {
        name: response.givenName,
        surname: response.familyName,
        provider_name: 'google',
        access_token: response.authentication.accessToken,
        email: response.email
      }

    }).catch(error => {
      console.warn("error", error)
    })
  }

  async appleLogin() {
    return SignInWithApple.authorize(this.options)
      .then(async (response: SignInWithAppleResponse) => {
        this.socialLogin('apple', response.response.identityToken)

        const socialData = JSON.parse((await Preferences.get({ key: 'socialData' })).value)
        if (response.response.givenName) {
          Preferences.set({ key: 'socialData', value: JSON.stringify(response.response) })

          this.socialData = {
            name: response.response.givenName,
            surname: response.response.familyName,
            provider_name: 'apple',
            access_token: response.response.identityToken,
            email: response.response.email
          }
        } else if (socialData.givenName) {
          this.socialData = {
            name: socialData.givenName,
            surname: socialData.familyName,
            provider_name: 'apple',
            access_token: socialData.identityToken,
            email: socialData.email
          }
        }

      })
      .catch(error => {
        console.warn("error", error)
      });
  }

  async socialLogin(provider_name: 'google' | 'apple', access_token: string) {
    return (await this.axios.getInstance())
      .post('/social-login', { provider_name, access_token })
      .then((response) => {
        this.analytics.logEvent(`log_in_${provider_name}`, {})
        this.saveToken(response.data.token)
        this.router.navigate(['/'])
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.router.navigate(['/signup/'+this.socialData.email])
        }
      })
  }

  async signUpWithSocial(phoneNumber, countryCode) {
    Preferences.remove({ key: 'socialData' })

    this.signUp({
      ...this.socialData,
      phone: `${countryCode} ${phoneNumber}`,
      subscribe: false
    }).then(() => {
      this.socialData = {}
      this.router.navigate(['/'])
    }).catch((error) => {
      this.socialData = {}
      this.toast.presentToast(error.response.data.message, 'error')
    })
  }

  async sendSmsCode(phone: string) {
    return (await this.axios.getInstance())
      .post('/sendsms', { phone })
      .then(() => {
        this.analytics.logEvent('request_sms_code', { phone })
      });
  }

  async sendEmailCode(email: string) {
    return (await this.axios.getInstance())
      .post('/sendemail', { email })
      .then((response) => {
        if (response.data?.verification_code) {
          this.verificationCode.next(response.data.verification_code)
        }
        this.analytics.logEvent('request_email_code', { email })
      });
  }

  async verifyCode(phone: string, code: number) {
    return (await this.axios.getInstance())
      .post('/checksms', { phone, code })
      .then((response) => {
        this.signedUrl = response.data.signed_url
        this.analytics.logEvent('send_sms_code', { phone, code })
      });
  }

  async verifyEmailCode(email: string, code: number) {
    return (await this.axios.getInstance())
      .post('/checkemail', { email, code })
      .then((response) => {
        this.signedUrl = response.data.signed_url
        this.analytics.logEvent('send_sms_code', { email, code })
      });
  }

  reset() {
    this.isPhoneValidated.next(null)
    this.token = undefined
    this.signedUrl = undefined
  }
}
