import { Component, OnInit } from '@angular/core';
import { GeolocationService } from '@services/geolocation/geolocation.service';
import { ModalsService } from '@services/modals/modals.service';
import { ToastService } from '@services/toast/toast-service';
import { UserService } from '@services/user/user.service';
import { ViajesService } from '@services/viajes/viajes.service';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

@Component({
  selector: 'app-modal-inform',
  templateUrl: './modal-inform.component.html',
})
export class ModalInformComponent implements OnInit {
  inputText: string
  date: Date
  userLocation
  sending: boolean = false

  constructor(
    private modalsService: ModalsService,
    private viajesService: ViajesService,
    private geolocationService: GeolocationService,
    private userService: UserService,
    private toastService: ToastService
  ) { }

  modalInform: CupertinoPane
  modalInformSettings: CupertinoSettings = {
    bottomClose: true,
    fastSwipeClose: false,
    buttonDestroy: true,
    parentElement: 'body',
  }

  ngOnInit() {
    this.modalInform = new CupertinoPane(
      '.modalInform',
      this.modalInformSettings
    )

    this.modalsService.modalInform.subscribe((modal) => {
      switch (modal?.action) {
        case 'present':
          this.modalsService.hideModal('modalTripStatus')
          this.date = new Date()
          this.userLocation = this.geolocationService.location.getValue()
          this.modalInform.present(...modal?.params)
          break
        case 'moveToBreak':
          this.modalInform.moveToBreak(modal?.params[0])
          break
        case 'hide':
          this.modalInform.hide()
          break
        case 'destroy':
          this.modalInform.destroy(...modal?.params)
          break
      }
    })
  }

  clearInput() {
    this.inputText = ''
  } 

  sendReport() {
    this.sending = true
    const trip = this.viajesService.tripToReport

    const info = {
      user_email: this.userService.user.getValue().email,
      user_name: this.userService.user.getValue().name,
      user_lastName: this.userService.user.getValue().surname,
      user_phone: this.userService.user.getValue().phone,
      id_waiis: trip.id,
      id_operador: trip.service_id,
      nombre_conductor: trip.extras.nombre,
      telefono_conductor: trip.extras.telefono,
      hora: this.date.toISOString(),
      hora_solicitud: trip.created_at,
      estado_viaje: trip.state,
      geolocalizacion_usuario: this.userLocation.coords,
      geolocalizacion_taxi: {
        lat: trip.extras?.latitud,
        lng: trip.extras?.longitud
      },
      descripcion: this.inputText
    }

    this.viajesService.reportProblem(info)
      .then(() => {
        this.sending = false
        this.toastService.presentToast('Tu mensaje se ha enviado correctamente', 'info', { position: 'top' })
        this.modalsService.hideModal('modalInform')
      })
      .catch(() => {
        this.sending = false
        this.toastService.presentToast(
          'Ha habido un error al enviar tu mensaje. Prueba de nuevo más tarde, o ponte en contacto con nosotros.',
          'error',
          { position: 'top' }
        )
      })

    this.clearInput()
  }
}
