import { Component, OnInit } from '@angular/core';
import { formatPrice } from '@app/utils';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
})
export class DataComponent implements OnInit {
  tripExpenses;
  taxiBalance;
  completedRoutesPercentage;
  percentageDiffRoutesCompleted;
  totalCo2Saved;
  co2SavedAsDriverPercentage;
  co2SavedAsPassengerPercentage;
  percentageDiffCo2Saved;

  constructor(private user: UserService) {
    const today = new Date().toISOString().split('T')[0];
    const startDate = today.slice(0, 8) + '01';
    const endDate = today;
    this.user.getPoolingData({ startDate, endDate });

    this.user.carpoolingData.subscribe((data) => {
      this.tripExpenses = formatPrice(data.expenses_saved);
      this.taxiBalance = formatPrice(data.balance_in_waiis);
      this.completedRoutesPercentage =
        data.total_routes !== 0
          ? (data.completed_routes / data.total_routes) * 100
          : 0;

      this.totalCo2Saved = data.co2_saved_driver + data.co2_saved_rider;
      this.co2SavedAsDriverPercentage =
        this.totalCo2Saved !== 0
          ? (data.co2_saved_driver / this.totalCo2Saved) * 100
          : 0;

      this.co2SavedAsPassengerPercentage =
        this.totalCo2Saved !== 0
          ? (data.co2_saved_rider / this.totalCo2Saved) * 100
          : 0;

      this.percentageDiffRoutesCompleted =
        data.percentage_diff_routes_completed;
      const totalPreviousCo2Saved =
        data.previous_co2_saved_driver + data.previous_co2_saved_rider;

      this.percentageDiffCo2Saved =
        this.totalCo2Saved !== 0
          ? ((this.totalCo2Saved - totalPreviousCo2Saved) /
              totalPreviousCo2Saved) *
            100
          : 0;
    });
  }

  ngOnInit() {}
}
