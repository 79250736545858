import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@services/translate/translate.service';

@Component({
  selector: 'app-google-login-button',
  templateUrl: './google.component.html',
})
export class GoogleComponent implements OnInit {
  public loaderElement: HTMLIonLoadingElement;
  @Output() callback? = new EventEmitter()

  constructor(
    private auth: AuthenticationService,
    private loadingCtrl: LoadingController,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    this.loaderElement = await this.loadingCtrl.create({
      message: this.translate.instant("login.open_with_google"),
      spinner: 'crescent',
    })
  }

  async onClick() {
    this.loaderElement.present();
    await this.auth.googleLogin()
    await this.loaderElement.dismiss();
    if (this.callback) {
      this.callback.emit()
    }
  }
}
