import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RoutinesService } from '@services/routines/routines.service';

@Injectable({
  providedIn: 'root',
})
export class RoutineConfigGuard implements CanLoad {
  constructor(
    private router: Router,
    private routines: RoutinesService,
  ) {}

  canLoad(): Observable<boolean> {
    const routineConfig = this.routines.routineConfig.getValue();

    if (routineConfig) {
      return of(true);
    } else {
      this.router.navigateByUrl('/route-config');
      return of(false);
    }
  }
}
