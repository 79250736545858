import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import { formatPrice, launchApp } from '@app/utils';
import { Platform } from '@ionic/angular';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { ComparadorService } from '@services/modals/comparador/comparador.service';
import { ModalsService } from '@services/modals/modals.service';
import { MyWaiisService } from '@services/modals/my-waiis/my-waiis.service';
import { VehiclesService } from '@services/vehicles/vehicles.service';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

@Component({
  selector: 'app-info-activo',
  templateUrl: './info-activo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoActivoComponent implements OnInit {
  activo = null
  address = ""
  image = ""
  comparadorWasOpen

  modalInfoActivo: CupertinoPane
  modalInfoActivoSettings: CupertinoSettings = {
    bottomClose: true,
    fastSwipeClose: false,
    buttonDestroy: false,
    fitHeight: true,
    events: {
      onDidDismiss: () => {
        this.vehicles.selectActivo(null)
        if (this.comparadorWasOpen) {
          this.comparador.openModal()
        }
        this.myWaiis.setInfoActivoVisible(false)
      },
      onWillPresent: () => {
        this.comparadorWasOpen = this.comparador.open.getValue()
        this.comparador.dismiss()
        this.myWaiis.setInfoActivoVisible(true)
      },
      onDidPresent: () => {
        this.changeDetector.detectChanges()
      }
    }
  }

  constructor(
    private vehicles: VehiclesService,
    private modals: ModalsService,
    private geocoder: MapGeocoder,
    private changeDetector: ChangeDetectorRef,
    private analytics: AnalyticsService,
    private platform: Platform,
    private comparador: ComparadorService,
    private myWaiis: MyWaiisService
  ) {
    this.modals.modalInfoActivo.subscribe((modal) => {
      switch (modal?.action) {
        case 'present':
          this.modalInfoActivo.present(...modal?.params)
          break
        case 'moveToBreak':
          this.modalInfoActivo.moveToBreak(modal?.params[0])
          break
        case 'hide':
          this.modalInfoActivo.hide()
          break
        case 'destroy':
          this.modalInfoActivo.destroy(...modal?.params)
          break
      }
    })

    this.vehicles.selectedActivo.subscribe(async activo => {
      this.activo = activo
      if (activo) {
        if (activo.lat && activo.lng) {
          await this.setAddress(activo)
        }
        if (activo.operador === 'bicing' || activo.operador === 'bizi' || activo.operador === 'bicimad') {
          this.image = `https://backend.waiis.eco/img/logos/${activo.operador}.svg`
        } else {
          this.image = `/assets/img/${activo.familia}_${activo.operador}.png`
        }
        this.modalInfoActivo.present().then(() => {
          this.changeDetector.detectChanges()
          setTimeout(() => this.modalInfoActivo.calcFitHeight(), 200)
        })
      } else {
        this.address = ""
        this.image = ""
      }
    })
  }

  openDeeplink() {
    this.analytics.logEvent(
      `abrir_${this.activo.operador}`,
      {
        familia: this.activo.familia,
        operador: this.activo.operador,
      }
    )

    if (this.activo.operador === 'cooltra' || this.activo.operador === 'goto') {
      window.open(this.activo.deeplink)
    } else {
      launchApp(this.activo.operador, this.platform.platforms())
    }
  }

  extractAddress(address) {
    const street = address.find(address_component => {
      return address_component.types.includes("route")
    })?.long_name

    const street_number = address.find(address_component => {
      return address_component.types.includes("street_number")
    })?.long_name

    return {street, street_number}
  }

  formatPrecio() {
    if (!this.activo.estimated) {
      return null
    }
    if (this.activo.estimated && this.activo.familia === 'taxi') {
      return formatPrice(this.activo.precio, 3);
    }

    return formatPrice(this.activo.precio);
  }

  async setAddress({lat, lng}) {
    const latLng = { lat, lng }

    return this.geocoder
      .geocode({ location: latLng })
      .forEach(res => {
        if (res.results.length > 0) {
          let address = this.extractAddress(res.results[0].address_components)
          let i = 1

          while (!address.street && i <= res.results.length) {
            i++
            address = this.extractAddress(res.results[i].address_components)
          }

          this.address = `${address.street}, ${address.street_number}`
          return
        }
      })
  }

  toLowerCase(string: string) {
    return string.toLowerCase()
  }

  ngOnInit() {
    this.modalInfoActivo = new CupertinoPane(
      '.modalInfoActivo',
      this.modalInfoActivoSettings
    )
  }
}
